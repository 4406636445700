import React, { useEffect, useState } from "react";
import Footer from "./Footer";
//import GalleryScreen from "./component/GalleryScreen";
import GalleryScreen from "./GalleryScreen";
import Innovations from "./Innovations";
import Header from "./Header";
import OurJourney from "./OurJourney";
import Partners from "./Partners";
import SuccessStories from "./SuccessStories";
import HeroSection from "./HeroSection";
import { ArrowComponent } from "./ArrowComponent";
import { AppAvailable } from "./AppAvailable";
import MobileViewFooter from "./MobileViewFooter";
import DashBoardMobileView from "./DashBoardMobileView";

const DashBoard = () => {
  const [isMobileView, setIsMobileView] = useState(false);

  // Update isMobileView based on screen size
  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 768); // Mobile view if width is less than 768px
    };

    // Set initial screen size
    handleResize();

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      {isMobileView ? (
        <>
          <div className="">
            <DashBoardMobileView />
          </div>
          <div className="mt-8">
            <MobileViewFooter />
          </div>
        </>
      ) : (
        <>
          <Header />
          <HeroSection />
          {/* <ArrowComponent /> */}
          <OurJourney />
          {/* <SuccessStories /> */}
          <GalleryScreen />
          <Innovations />
          <Partners />
          <AppAvailable />
          <Footer />
        </>
      )}
    </>
  );
};
export default DashBoard;
