import React from "react";
// import Herobg2 from "../assets/cardcurve2.png";
// import NirnalImg from "../assets/nirmal.png";
// import LInkDin from "../assets/linkedin.png";
// import Instagram from "../assets/insta.png";
// import KarnaImg from "../assets/karna.png";
import nirmal from "../assets/nirmalImage.png";
import karna from "../assets/karna.png";
import sarla from "../assets/sarala.png";
import bala from "../assets/bala.png";
import linkedin from "../assets/linkedin.png";
import insta from "../assets/insta.png";
import BestEV from "../assets/BestEV.png";
import Innovation from "../assets/Innovation.png";
import FutureEV from "../assets/FutureEV.png";
import IACC from "../assets/IACC.png";
import innovationsbg from "../assets/innovationsbg.png";
import EvTrans from "../assets/ohmgroup-automotive.png";
import EvLogistics from "../assets/ohmgroup-elogistics.png";
import Evcharging from "../assets/ohmgroup-charging.png";
import Prize from "../assets/Prize.png"; // Image for small screens

export default function DashboardPrizeMobileView() {
  return (
    <div>
      <div>
        <div className="flex flex-row items-center justify-center mb-4">
          {/* Left Divider */}
          <div
            className="flex-grow h-[2px] mx-2"
            style={{
              borderWidth: "1px",
              borderStyle: "solid",
              borderImage:
                "linear-gradient(270deg, #FFFFFF 3.14%, #10C06F 51.81%, #FFFFFF 98.57%) 1",
            }}
          ></div>

          {/* Image in the Center */}
          <img src={Prize} alt="Prize" className="" />

          {/* Right Divider */}
          <div
            className="flex-grow h-[2px] mx-2"
            style={{
              borderWidth: "1px",
              borderStyle: "solid",
              borderImage:
                "linear-gradient(270deg, #FFFFFF 3.14%, #10C06F 51.81%, #FFFFFF 98.57%) 1",
            }}
          ></div>
        </div>
      </div>
      <div className="grid grid-cols-2 gap-2 justify-center">
        {/* IACC Award */}
        <div className="flex flex-col items-center text-center">
          <img src={IACC} alt="IACC" />
          <span className="font-barlowcondensed font-normal tracking-normal text-[16px] sm:text-[18px] mt-2">
            IACC
          </span>
          <span className="tracking-normal font-normal text-[16px] sm:text-[18px] font-barlowcondensed">
            Energy Saver
          </span>
        </div>

        {/* Best EV Award */}
        <div className="flex flex-col items-center text-center">
          <img src={BestEV} alt="Best EV" />
          <span className="font-barlowcondensed font-normal text-[16px] sm:text-[18px] tracking-normal mt-2">
            Best EV Service
          </span>
          <span className="tracking-normal font-normal text-[16px] sm:text-[18px] font-barlowcondensed">
            2024
          </span>
        </div>

        {/* Innovation Award */}
        <div className="flex flex-col items-center text-center">
          <img src={Innovation} alt="Innovation" />
          <span className="font-barlowcondensed font-normal text-[16px] sm:text-[18px] tracking-normal mt-2">
            Innovation in
          </span>
          <span className="tracking-normal font-normal text-[16px] sm:text-[18px] font-barlowcondensed">
            Electric Vehicles
          </span>
        </div>

        {/* Future EV Award */}
        <div className="flex flex-col items-center text-center">
          <img src={FutureEV} alt="Future EV" />
          <span className="font-barlowcondensed text-[16px] sm:text-[18px] tracking-normal mt-2">
            Sustainable Future
          </span>
          <span className="tracking-normal text-[16px] sm:text-[18px] font-barlowcondensed">
            Electric Transport
          </span>
        </div>
      </div>
      <div
        style={{
          backgroundImage: `url(${innovationsbg})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          width: "100%",
          paddingBottom: "25px",
        }}
        className="flex flex-col justify-between"
      >
        <p className="text-[32px] md:text-[96px] text-center font-barlowcondensed font-light mt-10">
          OHM Group
        </p>
        <p className="text-[#0AAC76] text-[24px] md:text-[40px] text-center font-barlowcondensed font-normal">
          Innovations
        </p>

        {/* Content Section */}
        <div className="">
          {/* EV Transportation */}
          <div className="flex flex-wrap align-center justify-center">
            <div className="flex flex-col items-center justify-center">
              <img src={EvTrans} alt="ohm-automotive-auto" />
              <span className="text-[#173D3D] tracking-normal  mt-2 text-center font-roboto font-normal text-[20px] leading-[20px]">
                OHM Automotive Pvt. Ltd.
              </span>
            </div>

            {/* EV Logistics */}
            <div className="flex flex-col justify-center items-center mt-6">
              <img src={EvLogistics} alt="ohm-logistics-auto" />
              <span className="text-[#173D3D]  mt-4 text-center font-roboto font-normal text-[20px] ">
                OHM E Logistics Pvt. Ltd.
              </span>
            </div>

            {/* EV Charging Solutions */}
            <div className="flex flex-col justify-center items-center mt-12">
              <img src={Evcharging} alt="ohm-charging station" />
              <span className="text-[#173D3D]  mt-4 text-center font-roboto font-normal text-[20px] ">
                OHM Charging Solutions Pvt. Ltd.
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="mb-12 flex  flex-col justify-center items-center">
        {/* Heading */}
        <div className="flex flex-col text-center  ">
          <span className="text-[36px] md:text-[96px] font-barlowcondensed font-light">
            We are together
          </span>
          <span className="text-[#10C06F] text-[24px] font-barlowcondensed font-normal">
            Partners
          </span>
        </div>

        {/* Partner Grid */}
        <div className="grid grid-cols-2 lg:grid-cols-2 gap-8 md:gap-8">
          {/* Partner 1 */}
          <div className="flex flex-col items-center">
            <img
              src={nirmal}
              alt="nirmal"
              className="w-[150px] h-[150px] md:w-[200px] md:h-[200px]"
            />
            <span className="font-roboto font-normal text-[20px] leading-[26.4px] md:text-[24px] tracking-wide mt-4 text-center">
              M. Nirmal Reddy
            </span>
            <span className="tracking-wider text-center text-[14px] md:text-[16px] font-roboto font-normal leading-[17.6px]">
              Founder & CEO
            </span>
            <div className="flex gap-2 pt-2">
              <img
                src={insta}
                alt="Instagram"
                className="w-6 h-6 md:w-8 md:h-8"
              />
              <img
                src={linkedin}
                alt="LinkedIn"
                className="w-6 h-6 md:w-8 md:h-8"
              />
            </div>
          </div>

          {/* Partner 2 */}
          <div className="flex flex-col items-center">
            <img
              src={karna}
              alt="karna"
              className="w-[150px] h-[150px] md:w-[200px] md:h-[200px]"
            />
            <span className="font-roboto font-normal text-[20px] leading-[26.4px] md:text-[24px] tracking-wide mt-4 text-center">
              M. Karunakar Reddy
            </span>
            <span className="tracking-wider text-center text-[14px] md:text-[16px] font-roboto font-normal leading-[17.6px]">
              Co-Founder & CTO
            </span>
            <div className="flex gap-2 pt-2">
              <img
                src={insta}
                alt="Instagram"
                className="w-6 h-6 md:w-8 md:h-8"
              />
              <img
                src={linkedin}
                alt="LinkedIn"
                className="w-6 h-6 md:w-8 md:h-8"
              />
            </div>
          </div>

          {/* Partner 3 */}
          <div className="flex flex-col items-center">
            <img
              src={sarla}
              alt="sarla"
              className="w-[150px] h-[150px] md:w-[200px] md:h-[200px]"
            />
            <span className="font-roboto font-normal text-[20px] leading-[26.4px] md:text-[24px] tracking-wide mt-4 text-center">
              K. Sarala
            </span>
            <span className="tracking-wider text-center text-[14px] md:text-[16px] font-roboto font-normal leading-[17.6px]">
              Director
            </span>
            <div className="flex gap-2 pt-2">
              <img
                src={insta}
                alt="Instagram"
                className="w-6 h-6 md:w-8 md:h-8"
              />
              <img
                src={linkedin}
                alt="LinkedIn"
                className="w-6 h-6 md:w-8 md:h-8"
              />
            </div>
          </div>

          {/* Partner 4 */}
          <div className="flex flex-col items-center">
            <img
              src={bala}
              alt="bala"
              className="w-[150px] h-[150px] md:w-[200px] md:h-[200px]"
            />
            <span className="font-roboto font-normal text-[20px] leading-[26.4px] md:text-[24px] tracking-wide mt-4 text-center">
              Bala Krishna Ch
            </span>
            <span className="tracking-wider text-center text-[14px] md:text-[16px] font-roboto font-normal leading-[17.6px]">
              Director
            </span>
            <div className="flex gap-2 pt-2">
              <img
                src={insta}
                alt="Instagram"
                className="w-6 h-6 md:w-8 md:h-8"
              />
              <img
                src={linkedin}
                alt="LinkedIn"
                className="w-6 h-6 md:w-8 md:h-8"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
