import React from "react";

const Data = [
  { "01": "Power train" },
  { "02": "High-Capacity Passenger" },
  { "03": "Cargo Vehicles" },
  { "04": "Battery" },
  { "05": "Charging stations & many" },
];

const MobileViewCards = () => {
  return (
    <div className="grid grid-cols-2 gap-2 p-2">
      {Data.map((item, i) => {
        const [title, content] = Object.entries(item)[0];
        // Custom widths for each card
        const widths = ["w-40", "w-40", "w-50", "w-40", "w-60"];
        return (
          <div
            key={i}
            className={`${
              i === Data.length - 1
                ? "col-span-2 mx-auto" // Center the last card
                : ""
            } ${
              widths[i]
            } border border-green-300 rounded-lg p-2 shadow-md hover:shadow-lg transition bg-white flex gap-3 items-start`}
          >
            <p className="text-3xl font-bold text-green-custom mb-2">{title}</p>
            <p className="text-lg text-gray-700">{content}</p>
          </div>
        );
      })}
    </div>
  );
};

export default MobileViewCards;
