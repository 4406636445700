import React from "react";
import OurJourneyAuto from "../svgs/ourjourney";
import strategy from "../assets/strategybg.png";
import footimg from "../assets/footimg.png";
import percentageimg from "../assets/percentageimg.png";
import passengerseat from "../assets/passengerseat.png";

export default function OurJourneyMobileView() {
  return (
    <div>
      {/* Section Title */}
      <div className="flex items-end justify-center flex-wrap mt-8">
        <div className=" flex flex-col items-center text-center">
          <span className="font-barlowcondensed tracking-normal text-6xl font-light text-cyan-950 ">
            Our Journey
          </span>
          <div className="flex h-8 flex-shrink-0 items-end justify-center">
            <div className="text-center text-[20px] sm:text-[40px]  text-green-500 font-barlowcondensed font-normal">
              Since Nov 2022
            </div>
          </div>
        </div>
      </div>
      {/* Main Content */}
      <div className=" items-center ">
        <div className="">
          <div className="items-center">
            {/* Image on the left side */}
            <div className="flex items-center justify-center mt-6">
              <OurJourneyAuto className="h-62 sm:h-83 bg-white " />
            </div>

            {/* Text content on the right side */}
            <div className="flex flex-col w-full sm:w-[550px] order-2 sm:order-1 sm:pl-8">
              {/* 2023 and First Prototype on the same line */}
              <div className="flex justify-start ml-2">
                <div>
                  {/* <p className="text-[20px] sm:text-[48px] font-barlowcondensed font-light text-black  ">
                    2023
                  </p> */}
                  <p className="text-[30px] sm:text-[85px] font-barlowcondensed font-light text-[#0AAC76] ">
                    First Prototype - 2023
                  </p>
                </div>
              </div>

              <div className="text-start ml-2 mt-2 font-barlowcondensed tracking-normal text-sm md:text-base  font-normal ">
                <p>
                  We are running 40 electrical vehicles of Mahindra E Verito
                  which are spacious, green vehicles with all safety features
                  and air conditioning.
                  <br />
                  <br />
                  All the vehicles are brand new, & we have a good response from
                  the customers as we are clocking around 250 to 300 kms a day.
                </p>
                <div className="h-[19px]" />
                <p>
                  We are having charging yards with slow and fast charging
                  facility at different locations across India to support our
                  operations.
                </p>
              </div>

              {/* Who We Are button aligned at right */}
              <div className="flex justify-center w-full mt-8">
                {/* <div className="rounded-[31px] bg-green-50  p-2 "> */}
                <div className="ml-[2px] flex  items-end  justify-center rounded-[31px] border-2 border-solid  border-green-500 bg-green-50 pb-[17px] pl-[22px] pr-6 pt-[18px] shadow-lg shadow-green-300">
                  <div className="font-roboto  pl-10 pr-10  text-center text-[20px] font-medium leading-[18px]">
                    Who we are
                  </div>
                </div>
              </div>
              {/* </div> */}
            </div>
          </div>
        </div>
      </div>
      {/* Why Electrical, Mission, Strategy in a flex row */}
      <div className=" mt-10 ">
        <div className=" items-center ">
          <div className="font-barlowcondensed font-normal text-center text-2xl sm:text-[48px]  text-[#197D71]">
            Why Electrical
          </div>
          <div className="text-center mt-2">
            <p className="text-xs  font-barlowcondensed ">
              OHM chose electric cabs to lead the way in sustainable
              <br />
              transportation, driven by the vision of reducing
              <br /> environmental impact and offering an eco-friendly
              <br /> alternative to traditional fuel vehicles.
            </p>
          </div>
        </div>
        <div className=" items-center mt-4">
          <div className="font-barlowcondensed font-normal text-center text-2xl sm:text-[48px]  text-[#197D71]">
            Mission
          </div>
          <div className="text-center mt-2">
            <p className="text-xs  font-barlowcondensed ">
              Our mission is to be recognized as the global
              <br /> leader in Electric Vehicle Logistic Solutions for
              <br /> companies and the public and private sector by
              <br /> partnering with our clients to provide the best
              <br />
              and most efficient Logistic solutions and to
              <br /> achieve service excellence.
            </p>
          </div>
        </div>
        <div className=" items-center mt-4">
          <div className="font-barlowcondensed font-normal text-center text-2xl sm:text-[48px]  text-[#197D71]">
            Strategy
          </div>
          <div className="text-center mt-2">
            <p className="text-xs  font-barlowcondensed ">
              The strategy of OHM is to develop a viable &<br /> sustainable
              Electric Mobility Solutions globally. To
              <br /> be known as a Company which has made Electric
              <br /> Mobility Viable & reachable to every individual
              <br />
              business.
            </p>
          </div>
        </div>
      </div>
      <div className="flex flex-col relative mt-6">
        {/* Set strategy as background image */}
        <div
          style={{
            backgroundImage: `url(${strategy})`,
            backgroundSize: "cover",
            width: "100%",
            height: "auto",
            position: "relative",
          }}
        >
          <div className="flex justify-between px-4">
            <div className="py-4 space-y-3">
              <img src={footimg} alt="foot" width={60} height={20} />
              <p className="text-green-700 font-bold text-xs ml-4">
                Zero <br /> Emission
              </p>
            </div>
            <div className="py-4 space-y-3">
              <img
                src={percentageimg}
                alt="percentage"
                width={60}
                height={20}
              />
              <p className="text-green-700 font-bold text-xs">
                Cheaper <br />
                to Run
              </p>
            </div>{" "}
            <div className="py-4 space-y-3">
              <img
                src={passengerseat}
                alt="passengerseat"
                width={60}
                height={20}
              />
              <p className="text-green-700 font-bold text-xs">
                Safety & <br />
                Comfort
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
