import React from "react";
import gallerybg from "../assets/gallerybg.png";
import Firstpic from "../assets/Group 592.png";
import Secondpic from "../assets/Group 593 (1).png";
import Thirdpic from "../assets/Group 594 (1).png";
import Fourthpic from "../assets/Group 595 (1).png";

export default function GalleryScreenMobileView() {
  return (
    <div>
      <div
        style={{
          // backgroundImage: `url(${gallerybg})`,
          backgroundSize: "cover",
          backgroundPosition: "top",
          width: "100%",
          paddingTop: "20px",
          paddingBottom: "20px",
          paddingLeft: "40px",
        }}
        className="items-center"
      >
        <div className="">
          <div className=" text-center mt-6">
            <span className="text-6xl md:text-[128px] text-center font-barlowcondensed font-light text-gray-700 ">
              Gallery
            </span>
            <div>
              <span className="text-green-600 text-[20px]  font-barlowcondensed">
                From Vision to Revolution
              </span>
            </div>
          </div>
        </div>
        <div className="w-full flex justify-center items-center mt-8">
          <div className="grid grid-cols-2 gap-1 md:gap-0">
            <div className="items-start justify-end">
              <img src={Thirdpic} alt="Third " className="object-contain " />
            </div>
            <div className="flex items-end justify-start w-32">
              <img src={Firstpic} alt="First " className="object-contain " />
            </div>
            <div className="flex items-start justify-start pl-14">
              <img src={Fourthpic} alt="Fourth " className="object-contain " />
            </div>
            <div className="row-span-1">
              <img src={Secondpic} alt="Second " className="object-contain " />
            </div>
          </div>
        </div>
        <div className="flex justify-center w-full mt-6 ">
          <div className="ml-0 flex pl-10 pr-10 items-center justify-center rounded-full border-2 border-green-500 bg-green-50 py-4 px-6  cursor-pointer hover:bg-green-100 transition duration-300 shadow-lg shadow-green-300">
            <div className="text-center text-[20px] font-roboto font-semibold text-black leading-[23.44px]">
              Explore the Future
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
