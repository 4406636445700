import React from "react";
import Navbar from "./NavBar";
import Herobg2 from "../assets/cardcurve2.png";
import NirnalImg from "../assets/nirmal.png";
import LInkDin from "../assets/linkedin.png";
import Instagram from "../assets/insta.png";
import KarnaImg from "../assets/karna.png";
import nirmal from "../assets/nirmalImage.png";
import karna from "../assets/karna.png";
import sarla from "../assets/sarala.png";
import bala from "../assets/bala.png";
import linkedin from "../assets/linkedin.png";
import insta from "../assets/insta.png";
import quotes from "../assets/quotes.png";
import ElectricImg from "../assets/electric.png";
import BestEV from "../assets/BestEV.png";
import Innovation from "../assets/Innovation.png";
import FutureEV from "../assets/FutureEV.png";
import IACC from "../assets/IACC.png";
import innovationsbg from "../assets/innovationsbg.png";
import EvTrans from "../assets/ohmgroup-automotive.png";
import EvLogistics from "../assets/ohmgroup-elogistics.png";
import Evcharging from "../assets/ohmgroup-charging.png";
import Prize from "../assets/Prize.png"; // Image for small screens

export default function AboutPageMobileView() {
  return (
    <>
      <div
        className="justify-center items-center flex flex-col"
        style={{
          backgroundSize: "100%",
          //   backgroundPosition: "left",
          backgroundRepeat: "no-repeat",
          // backgroundImage: linear-gradient(to right, #1e3a8a,
          //   #6d28d9, #ec4899),
        }}
      >
        <div className="w-full bg-gradient-to-b from-green-100 to-slate-50">
          <Navbar />
          <div className="flex flex-row justify-center items-center mt-8">
            <div className=" text-center">
              <span className="font-barlowcondensed text-[50px] font-light text-cyan-900 leading-none">
                About OHM
              </span>
              <p className="text-center text-[22px] sm:text-[40px] text-green-600 font-barlowcondensed font-normal">
                Our Vision & Values{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div>
        <img src={NirnalImg} alt="Nirmalsir" className="w-[300px] ml-6" />
        <div className="ml-32">
          <p>M Nirmal Reddy</p>
          <p>Founder & CEO</p>
          <div className="flex flex-row gap-2 ml-2 justify-start">
            <img src={LInkDin} alt="linkdin" />
            <img src={Instagram} alt="instagram" />
          </div>
        </div>
      </div>
      <div>
        <p className="mb-10 mt-8 text-start ml-4">
          At OHM, we believe that the future of transportation lies in
          sustainable practices. Our electric{" "}
          <strong>2 wheelers, 3 wheelers, and 4 wheelers</strong> represent a
          commitment to innovation combined with environmental stewardship. Each
          vehicle is designed not only for performance and efficiency but also
          with a focus on reducing our carbon footprint and contributing to
          cleaner urban air. With a sleek modern aesthetic and cutting-edge
          technology, our vehicles don’t just get you from point A to point B;
          they also champion a sustainable future.
        </p>
      </div>
      <div className=" flex flex-col item-center justify-center ">
        <img
          src={KarnaImg}
          alt="karnasir"
          className="w-[100px] ml-32 border-green-600 border-2 rounded-full"
        />
        <div className="ml-28">
          <p className="pt-3">M. Karunakar Reddy</p>
          <p className="">Co-Founder & CEO</p>
          <div className="flex flex-row ml-6 gap-2 mt-2">
            <img src={LInkDin} alt="linkdin" />
            <img src={Instagram} alt="instagram" />
          </div>
        </div>
      </div>
      <div>
        <p className="mb-8 mt-8 text-start ml-4">
          Our car and cargo leasing extensions are tailored to meet the unique
          needs of every customer, whether a small business owner looking to
          optimize logistics or a family seeking eco-friendly transportation
          options. Our tracking and fleet management systems provide
          unparalleled real-time insights, ensuring not only the safety of your
          assets but also the efficiency of your operations. We offer
          personalized solutions that reflect our customers’ specific
          requirements, making their experience smooth and hassle-free. 
        </p>
        <p className="mb-10 mt-8 text-start ml-4">
          We are a startup with a foundation built on a decade of experience in
          Renewable Solar Power Projects, we’ve committed ourselves not just to
          harnessing energy, but to reshaping the future of mobility. For the
          past 10 years, our team has passionately dedicated itself to fostering
          a sustainable environment, championing the cause of clean energy
          solutions that empower communities and protect our planet. As pioneers
          in the renewable energy sector, we recognize that the road to a
          sustainable future begins with redefining how we travel. Our vision
          comes into full swing with OHM E-VEHICLES, where we’re not just
          bringing electric vehicles to the market—we’re electrifying the entire
          transport ecosystem. Our offerings extend beyond cars, seamlessly
          integrating OHM E CHARGING stations that serve as vital nodes in this
          electrifying journey, ensuring drivers have easy access to the fast
          and efficient charging they need.
        </p>
      </div>
      <div className="p-2">
        <div className="flex justify-start py-4">
          <img
            src={quotes}
            alt="quotes"
            className="object-contain"
            style={{
              maxWidth: "50px",
              maxHeight: "50px",
              transform: "scaleX(-1)", // Flip the image horizontally
            }}
          />
        </div>
        <div className="pl-6 text-[30px]  font-barlowcondensed  font-light italic text-gray-600">
          <p className="text-center text-[30px]  font-barlowcondensed  font-light italic text-gray-500">
            In a world where the clock
            <br /> is ticking on the environmental
            <br /> crisis, OHM E-VEHICLES Emerges
            <br /> as a beacon of hope and <br /> innovation.
          </p>
        </div>
        <div className="flex justify-end py-4">
          <img
            src={quotes}
            alt="quotes"
            className="object-contain"
            style={{
              maxWidth: "50px",
              maxHeight: "50px",
              transform: "scaleY(-1)", // Flip the image horizontally
            }}
          />
        </div>
      </div>
      <div className="w-[100%] px-4 ">
        <div
          style={{
            backgroundImage: `url(${ElectricImg})`,
            backgroundSize: "100% ",
            backgroundPosition: "center",
            //marginBottom: "100px !important",
            backgroundRepeat: "no-repeat",
            width: "100%",
            height: "40%",
          }}
          className="flex flex-row justify-center"
        >
          <div className="w-1/3"></div>
          <div className=" flex flex-col justify-center py-2">
            <p className="font-barlowsemicondensed font-semibold text-xs tracking-tighter">
              Let’s accelerate the transition to a sustainable world
            </p>
            <p className="font-barlowsemicondensed font-semibold text-xs tracking-tighter">
              united by clean energy and electric mobility solutions.
            </p>
            <p className="text-start text-xs tracking-tighter sm:text-[28px] font-barlowsemicondensed font-semibold italic text-green-600">
              Drive electric. Drive green. Drive OHM E-Auto.
            </p>
          </div>
        </div>
      </div>
      <div>
        <div className="flex flex-row items-center justify-center mb-4">
          {/* Left Divider */}
          <div
            className="flex-grow h-[2px] mx-2"
            style={{
              borderWidth: "1px",
              borderStyle: "solid",
              borderImage:
                "linear-gradient(270deg, #FFFFFF 3.14%, #10C06F 51.81%, #FFFFFF 98.57%) 1",
            }}
          ></div>

          {/* Image in the Center */}
          <img src={Prize} alt="Prize" className="" />

          {/* Right Divider */}
          <div
            className="flex-grow h-[2px] mx-2"
            style={{
              borderWidth: "1px",
              borderStyle: "solid",
              borderImage:
                "linear-gradient(270deg, #FFFFFF 3.14%, #10C06F 51.81%, #FFFFFF 98.57%) 1",
            }}
          ></div>
        </div>
      </div>
      <div className="grid grid-cols-2 gap-2 justify-center">
        {/* IACC Award */}
        <div className="flex flex-col items-center text-center">
          <img src={IACC} alt="IACC" />
          <span className="font-barlowcondensed font-normal tracking-normal text-[16px] sm:text-[18px] mt-2">
            IACC
          </span>
          <span className="tracking-normal font-normal text-[16px] sm:text-[18px] font-barlowcondensed">
            Energy Saver
          </span>
        </div>

        {/* Best EV Award */}
        <div className="flex flex-col items-center text-center">
          <img src={BestEV} alt="Best EV" />
          <span className="font-barlowcondensed font-normal text-[16px] sm:text-[18px] tracking-normal mt-2">
            Best EV Service
          </span>
          <span className="tracking-normal font-normal text-[16px] sm:text-[18px] font-barlowcondensed">
            2024
          </span>
        </div>

        {/* Innovation Award */}
        <div className="flex flex-col items-center text-center">
          <img src={Innovation} alt="Innovation" />
          <span className="font-barlowcondensed font-normal text-[16px] sm:text-[18px] tracking-normal mt-2">
            Innovation in
          </span>
          <span className="tracking-normal font-normal text-[16px] sm:text-[18px] font-barlowcondensed">
            Electric Vehicles
          </span>
        </div>

        {/* Future EV Award */}
        <div className="flex flex-col items-center text-center">
          <img src={FutureEV} alt="Future EV" />
          <span className="font-barlowcondensed text-[16px] sm:text-[18px] tracking-normal mt-2">
            Sustainable Future
          </span>
          <span className="tracking-normal text-[16px] sm:text-[18px] font-barlowcondensed">
            Electric Transport
          </span>
        </div>
      </div>
      <div
        style={{
          backgroundImage: `url(${innovationsbg})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          width: "100%",
          paddingBottom: "25px",
        }}
        className="flex flex-col justify-between"
      >
        <p className="text-[32px] md:text-[96px] text-center font-barlowcondensed font-light mt-10">
          OHM Group
        </p>
        <p className="text-[#0AAC76] text-[24px] md:text-[40px] text-center font-barlowcondensed font-normal">
          Innovations
        </p>

        {/* Content Section */}
        <div className="">
          {/* EV Transportation */}
          <div className="flex flex-wrap align-center justify-center">
            <div className="flex flex-col items-center justify-center">
              <img src={EvTrans} alt="ohm-automotive-auto" />
              <span className="text-[#173D3D] tracking-normal  mt-2 text-center font-roboto font-normal text-[20px] leading-[20px]">
                OHM Automotive Pvt. Ltd.
              </span>
            </div>

            {/* EV Logistics */}
            <div className="flex flex-col justify-center items-center mt-6">
              <img src={EvLogistics} alt="ohm-logistics-auto" />
              <span className="text-[#173D3D]  mt-4 text-center font-roboto font-normal text-[20px] ">
                OHM E Logistics Pvt. Ltd.
              </span>
            </div>

            {/* EV Charging Solutions */}
            <div className="flex flex-col justify-center items-center mt-12">
              <img src={Evcharging} alt="ohm-charging station" />
              <span className="text-[#173D3D]  mt-4 text-center font-roboto font-normal text-[20px] ">
                OHM Charging Solutions Pvt. Ltd.
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className=" mb-12 flex  flex-col justify-center items-center">
        {/* Heading */}
        <div className="flex flex-col text-center  ">
          <span className="text-[36px] md:text-[96px] font-barlowcondensed font-light">
            We are together
          </span>
          <span className="text-[#10C06F] text-[24px] font-barlowcondensed font-normal">
            Partners
          </span>
        </div>

        {/* Partner Grid */}
        <div className="grid grid-cols-2 lg:grid-cols-2 gap-8 md:gap-8">
          {/* Partner 1 */}
          <div className="flex flex-col items-center">
            <img
              src={nirmal}
              alt="nirmal"
              className="w-[150px] h-[150px] md:w-[200px] md:h-[200px]"
            />
            <span className="font-roboto font-normal text-[20px] leading-[26.4px] md:text-[24px] tracking-wide mt-4 text-center">
              M. Nirmal Reddy
            </span>
            <span className="tracking-wider text-center text-[14px] md:text-[16px] font-roboto font-normal leading-[17.6px]">
              Founder & CEO
            </span>
            <div className="flex gap-2 pt-2">
              <img
                src={insta}
                alt="Instagram"
                className="w-6 h-6 md:w-8 md:h-8"
              />
              <img
                src={linkedin}
                alt="LinkedIn"
                className="w-6 h-6 md:w-8 md:h-8"
              />
            </div>
          </div>

          {/* Partner 2 */}
          <div className="flex flex-col items-center">
            <img
              src={karna}
              alt="karna"
              className="w-[150px] h-[150px] md:w-[200px] md:h-[200px]"
            />
            <span className="font-roboto font-normal text-[20px] leading-[26.4px] md:text-[24px] tracking-wide mt-4 text-center">
              M. Karunakar Reddy
            </span>
            <span className="tracking-wider text-center text-[14px] md:text-[16px] font-roboto font-normal leading-[17.6px]">
              Co-Founder & CTO
            </span>
            <div className="flex gap-2 pt-2">
              <img
                src={insta}
                alt="Instagram"
                className="w-6 h-6 md:w-8 md:h-8"
              />
              <img
                src={linkedin}
                alt="LinkedIn"
                className="w-6 h-6 md:w-8 md:h-8"
              />
            </div>
          </div>

          {/* Partner 3 */}
          <div className="flex flex-col items-center">
            <img
              src={sarla}
              alt="sarla"
              className="w-[150px] h-[150px] md:w-[200px] md:h-[200px]"
            />
            <span className="font-roboto font-normal text-[20px] leading-[26.4px] md:text-[24px] tracking-wide mt-4 text-center">
              K. Sarala
            </span>
            <span className="tracking-wider text-center text-[14px] md:text-[16px] font-roboto font-normal leading-[17.6px]">
              Director
            </span>
            <div className="flex gap-2 pt-2">
              <img
                src={insta}
                alt="Instagram"
                className="w-6 h-6 md:w-8 md:h-8"
              />
              <img
                src={linkedin}
                alt="LinkedIn"
                className="w-6 h-6 md:w-8 md:h-8"
              />
            </div>
          </div>

          {/* Partner 4 */}
          <div className="flex flex-col items-center">
            <img
              src={bala}
              alt="bala"
              className="w-[150px] h-[150px] md:w-[200px] md:h-[200px]"
            />
            <span className="font-roboto font-normal text-[20px] leading-[26.4px] md:text-[24px] tracking-wide mt-4 text-center">
              Bala Krishna Ch
            </span>
            <span className="tracking-wider text-center text-[14px] md:text-[16px] font-roboto font-normal leading-[17.6px]">
              Director
            </span>
            <div className="flex gap-2 pt-2">
              <img
                src={insta}
                alt="Instagram"
                className="w-6 h-6 md:w-8 md:h-8"
              />
              <img
                src={linkedin}
                alt="LinkedIn"
                className="w-6 h-6 md:w-8 md:h-8"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
