import React, { useEffect, useState } from "react";
import Navbar from "./NavBar";
import Herobg2 from "../assets/cardcurve2.png";
import NirnalImg from "../assets/nirmal.png";
import KarnaImg from "../assets/karna.png";
import ElectricImg from "../assets/electric.png";
import Award from "./AwardsSection";
import Innovations from "./Innovations";
import { AppAvailable } from "./AppAvailable";
import Footer from "./Footer";
import LInkDin from "../assets/linkedin.png";
import Instagram from "../assets/insta.png";
import Header from "./Header";
import MobileViewFooter from "./MobileViewFooter";
import AboutPageMobileView from "./AboutPageMobileView";

const About = () => {
  const [isMobileView, setIsMobileView] = useState(false);

  // Update isMobileView based on screen size
  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 768); // Mobile view if width is less than 768px
    };

    // Set initial screen size
    handleResize();

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      {isMobileView ? (
        <>
          <div>
            <AboutPageMobileView />
          </div>
          <div className="mt-8">
            <MobileViewFooter />
          </div>
        </>
      ) : (
        <>
          <Header />
          <div
            className="justify-center items-center flex flex-col "
            style={{
              backgroundImage: `url(${Herobg2})`,
              backgroundSize: "100% ",
              backgroundPosition: "Top",
              //marginBottom: "100px !important",
              backgroundRepeat: "no-repeat",
            }}
          >
            <div className="w-full">
              <Navbar />
            </div>

            <div className="flex flex-row justify-center align-center m-10">
              <div className=" w-1/2 mt-24 ml-">
                <span className="font-barlowcondensed tracking-light text-[128px] font-light leading-[153.6px] text-cyan-950 ">
                  About OHM
                </span>
                <p className="text-start text-[20px] sm:text-[40px] leading-[48px] text-green-500 font-barlowcondensed font-normal">
                  Our Vision & Values
                </p>
                <div className=" text-left font-barlowcondensed tracking-normal text-[16px] md:text-base  font-normal mt-44  w-[635px]  ">
                  <p className="mb-10">
                    At OHM, we believe that the future of transportation lies in
                    sustainable practices. Our electric{" "}
                    <strong>2 wheelers, 3 wheelers, and 4 wheelers</strong>{" "}
                    represent a commitment to innovation combined with
                    environmental stewardship. Each vehicle is designed not only
                    for performance and efficiency but also with a focus on
                    reducing our carbon footprint and contributing to cleaner
                    urban air. With a sleek modern aesthetic and cutting-edge
                    technology, our vehicles don’t just get you from point A to
                    point B; they also champion a sustainable future.
                  </p>

                  <p className="mb-10">
                    Our car and cargo leasing extensions are tailored to meet
                    the unique needs of every customer, whether a small business
                    owner looking to optimize logistics or a family seeking
                    eco-friendly transportation options. Our tracking and fleet
                    management systems provide unparalleled real-time insights,
                    ensuring not only the safety of your assets but also the
                    efficiency of your operations. We offer personalized
                    solutions that reflect our customers’ specific requirements,
                    making their experience smooth and hassle-free. 
                  </p>

                  <p className="mb-10">
                    We are a startup with a foundation built on a decade of
                    experience in Renewable Solar Power Projects, we’ve
                    committed ourselves not just to harnessing energy, but to
                    reshaping the future of mobility. For the past 10 years, our
                    team has passionately dedicated itself to fostering a
                    sustainable environment, championing the cause of clean
                    energy solutions that empower communities and protect our
                    planet. As pioneers in the renewable energy sector, we
                    recognize that the road to a sustainable future begins with
                    redefining how we travel. Our vision comes into full swing
                    with OHM E-VEHICLES, where we’re not just bringing electric
                    vehicles to the market—we’re electrifying the entire
                    transport ecosystem. Our offerings extend beyond cars,
                    seamlessly integrating OHM E CHARGING stations that serve as
                    vital nodes in this electrifying journey, ensuring drivers
                    have easy access to the fast and efficient charging they
                    need.
                  </p>
                </div>
              </div>
              <div className="w-1/2">
                <div className=" flex flex-col item-center justify-start  mt-20">
                  <img src={NirnalImg} alt="Nirmalsir" className="w-[431px]" />
                  <div className="ml-52">
                    <p>M Nirmal Reddy</p>
                    <p>Founder & CEO</p>
                    <div className="flex flex-row gap-2 ml-2 justify-start">
                      <img src={LInkDin} alt="linkdin" />
                      <img src={Instagram} alt="instagram" />
                    </div>
                  </div>
                  <img
                    src={KarnaImg}
                    alt="Nirmalsir"
                    className="w-[100px] ml-52 mt-10"
                  />
                  <div className="ml-52">
                    <p>M Nirmal Reddy</p>
                    <p>Founder & CEO</p>
                    <div className="flex flex-row ml-2 gap-2">
                      <img src={LInkDin} alt="linkdin" />
                      <img src={Instagram} alt="instagram" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mb-20">
              <p className="font-light text-[73px] text-green-customs-600">“</p>
              <div className="text-start text-[20px] sm:text-[40px] leading-[48px] font-barlowcondensed  font-light italic text-gray-600">
                <p>
                  In a world where the clock is ticking on the environmental
                  crisis,
                </p>
                <p>
                  {" "}
                  OHM E-VEHICLES Emerges as a beacon of hope and innovation.
                </p>
              </div>

              <div className="flex flex-row justify-end">
                <p className="font-light text-[73px]  text-green-customs-600 ">
                  “
                </p>
              </div>
            </div>
            <div className="w-[1100px]">
              <div
                style={{
                  backgroundImage: `url(${ElectricImg})`,
                  backgroundSize: "100% ",
                  backgroundPosition: "center",
                  //marginBottom: "100px !important",
                  backgroundRepeat: "no-repeat",
                  width: "100%",
                  height: "264px",
                }}
                className="flex flex-row justify-center  w-full"
              >
                <div className="w-1/2"></div>
                <div className=" flex flex-col justify-center mr-4">
                  <p className="font-barlowsemicondensed font-normal leading-[34px] text-[20px]">
                    Let’s accelerate the transition to a sustainable world
                  </p>
                  <p className="font-barlowsemicondensed font-normal leading-[34px] text-[20px]">
                    united by clean energy and electric mobility solutions.
                  </p>
                  <p className="text-start text-[20px] sm:text-[28px] leading-[28px] font-barlowsemicondensed font-normal italic text-green-600">
                    Drive electric. Drive green. Drive OHM E-Auto.
                  </p>
                </div>
              </div>
            </div>
            <div className="">
              <Award />
            </div>

            <Innovations />
            <div className="w-full">
              <AppAvailable />
            </div>

            <Footer />
          </div>
        </>
      )}
    </>
  );
};
export default About;
