import React from "react";
// import { AppAvailable } from "./AppAvailable";
// import Footer from "./Footer";
import playstore from "../assets/googleplaystore-img2.png";
import appstore from "../assets/appleappstore-img.png";
import appimg from "../assets/app-img.png";
import footerImage from "../assets/foooterimage.png"; // Corrected spelling of the image file
// import Address from "../svgs/address-icon";
// import Mail from "../svgs/mail-icon";

export default function MobileViewFooter() {
  return (
    <div>
      <div className="flex flex-col items-center justify-evenly bg-custom-gradient">
        <div className="flex flex-col md:flex-row gap-8 md:gap-20 items-center justify-center md:justify-between">
          {/* Text Section */}
          <div className="flex flex-col items-center md:items-start justify-around">
            <div className="text-center md:text-left">
              <p className="font-barlowcondensed tracking-normal pt-6 text-[40px] md:text-[120px] text-[#FFFFFF] leading-[60px] md:leading-[130px]">
                OHM electric cabs
              </p>

              <p className="font-barlowcondensed tracking-wider font-light text-[20px] md:text-[15px] w-[300px] md:w-[420px] text-[#FFFFFF] leading-[25px] md:leading-[39px]">
                Experience Luxury, The Green Way with <br />
                OHM Electric Cabs!
              </p>
            </div>
            {/* Links Section */}
            <div className="container flex items-center mt-8 gap-4">
              <a href="https://play.google.com/store/search?q=ohm+electric+cabs&c=apps">
                <img
                  src={playstore}
                  alt="playstore"
                  className="w-[140px] h-[50px] md:w-[212px] md:h-[67px]"
                />
              </a>
              <a href="https://apps.apple.com/us/app/ohm-electric-cabs/id1668975054">
                <img
                  src={appstore}
                  alt="appstore"
                  className="w-[140px] h-[50px] md:w-[212px] md:h-[67px]"
                />
              </a>
            </div>
          </div>
          {/* Image Section */}
          <div>
            <img
              src={appimg}
              alt="appimg"
              className="w-[200px] h-[220px] md:w-[368px] md:h-[402px]"
            />
          </div>
        </div>
      </div>
      <div>
        <div
          style={{
            // backgroundImage: `url(${footerImage})`,
            backgroundSize: "cover",
            backgroundPosition: "bottom",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            // padding: "10px",
            backgroundColor: "#333333",
            color: "white",
            width: "100%",
          }}
          className="flex flex-wrap justify-center items-center"
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              flexGrow: 1,
              width: "100%",
            }}
            className="container"
          >
            <div className="flex flex-col md:flex-row md:justify-evenly w-full items-center text-center md:text-left">
              {/* About Section */}
              <div className="bg-transparent md:w-[546px] w-full p-4">
                <p className="text-[#F1BF1C] text-[50px] md:text-[100px] font-barlowcondensed tracking-normal font-light leading-[115px]">
                  About
                </p>
                <p className="md:text-[16px] tracking-normal font-roboto font-normal leading-[18px]">
                  Electric cars are predicted to be the next disruptive market
                  force for transportation and technology. They have the
                  potential to revolutionize how energy is used, created, and
                  redirected. One of the primary reasons for the introduction of
                  electric cars into the market is the concern over greenhouse
                  gas emissions and their contribution to global warming.
                  <p className="py-2 font-nova-light leading-[18px] text-[16px]">
                    E-Cargo Vehicles fully understand the world of logistics is
                    changing. Last mile delivery brings with it its own
                    challenging problems: emissions levels, congestion, cost,
                    reliability, and ever-demanding client deadlines.
                  </p>
                </p>
                <div className="flex justify-center md:justify-start w-full mt-4">
                  <div className="ml-[7px] flex w-[236px] items-center justify-center rounded-[31px] border-2 border-solid border-yellow-500 pb-[17px] pl-[22px] pr-6 pt-[18px] shadow-sm shadow-gray-50">
                    <div className="font-roboto text-center text-lg font-semibold leading-[normal] text-white">
                      More Info
                    </div>
                  </div>
                </div>
              </div>

              {/* Contact Section */}
              <div
                className="md:w-1/3 w-full"
                style={{
                  backgroundImage: `url(${footerImage})`,
                  backgroundSize: "cover",
                  backgroundPosition: "bottom",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  // padding: "10px",
                  backgroundColor: "#333333",
                  color: "white",
                  width: "100%",
                }}
              >
                <p className="text-[#F1BF1C] text-[50px] pb-0 md:text-[120px] font-barlowcondensed tracking-normal font-light leading-[115px]">
                  Contact
                </p>
                <div className="flex flex-col items-center md:items-start w-full">
                  {/* Icons and text */}
                  <div className="flex flex-col items-center md:items-start text-[16px] font-roboto font-normal leading-[20px] w-full">
                    <div className="flex items-center justify-center w-full">
                      <p className="ml-0">
                        H.O, 20B, ASCI Campus, Road No 5, <br />
                        Banjara Hills, Hyderabad - 500034.
                      </p>
                    </div>
                    <div className="w-full py-2 px-2">
                      <div className="border-t border-[#4E4E4E]" />
                    </div>
                    <div className="flex items-center w-full">
                      <p className="ml-0 w-full">
                        <a href="mailto:sales@ohmautomotives.com">
                          sales : +918142033344
                        </a>
                        <br />
                        <a href="mailto:support@ohmautomotives.com">
                          support : +918142033388
                        </a>
                      </p>
                    </div>
                    <div className="w-full py-2 px-2">
                      <div className="border-t border-[#4E4E4E]" />
                    </div>
                    <p>Contact@ohmautomotives.com</p>
                  </div>
                </div>
              </div>
            </div>

            {/* Terms & Privacy Policy */}
            <div className="w-full h-[120px] text-center mt-4">
              <div className="border-t border-[#4E4E4E]" />
              <div className="text-[#F1BF1C] mt-4">
                <a
                  href="/terms"
                  className="font-roboto font-normal leading-[37.05px]"
                >
                  Terms & Conditions
                </a>{" "}
                |{" "}
                <a
                  href="/privacy"
                  className="font-roboto font-normal leading-[37.05px]"
                >
                  Privacy Policy
                </a>
              </div>
              <p className="text-white font-roboto font-normal leading-[10.05px]">
                OHM Automotive Pvt. Ltd. <br />{" "}
                <p className="pt-2">Copyright © 2024. All Rights Reserved®</p>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
