import React from "react";
import Card from "./Card";
import Navbar from "./NavBar";
import Herobg2 from "../assets/cardcurve2.png";
//import DownArrow from "../svgs/downarrow";
//import { ArrowComponent } from "./ArrowComponent";

export default function HeroSection() {
  return (
    <div
      style={{
        backgroundImage: `url(${Herobg2})`,
        backgroundSize: "100% ",
        backgroundPosition: "top",
        //marginBottom: "100px !important",
        backgroundRepeat: "no-repeat",
      }}
    >
      <Navbar />
      <div className="justify-center items-center flex ">
        <Card />
      </div>
    </div>
  );
}
