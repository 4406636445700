import React, { useEffect, useState } from "react";
import { FaChevronDown, FaChevronUp } from "react-icons/fa"; // Import the Chevron icons
import Herobg2 from "../assets/cardcurve2.png";
import Navbar from "./NavBar";
import Product from "../assets/productbg.png";
import whiteAuto from "../assets/produAuto.png";
import BlueAuto from "../assets/BlueAuto.png";
import FrontRace from "../assets/RaceXL/front.png";
import SideRace from "../assets/RaceXL/side-view.png";
import InsideRace from "../assets/RaceXL/inside.png";
import BackRace from "../assets/RaceXL/back.png";
import FrontPace from "../assets/Pace/front.png";
import SidePace from "../assets/Pace/sideview.png";
import InsidePace from "../assets/Pace/inside.png";
import BackPace from "../assets/Pace/back.png";
import ProductContentArray from "./ProductContentArray";

export default function ProductsMobileView() {
  const [showData, setShowData] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [visibleCategory, setVisibleCategory] = useState(null); // Track the currently visible category

  const raceImages = [whiteAuto, whiteAuto, whiteAuto, whiteAuto];
  const paceImages = [BlueAuto, BlueAuto, BlueAuto, BlueAuto];
  const raceMiniImages = [FrontRace, SideRace, InsideRace, BackRace];
  const paceMiniImages = [FrontPace, SidePace, InsidePace, BackPace];

  // Select auto images and mini images based on the active category
  const autoImages = visibleCategory === "race" ? raceImages : paceImages;
  const miniImages =
    visibleCategory === "race" ? raceMiniImages : paceMiniImages;

  const handleToggleContent = (category) => {
    setVisibleCategory(visibleCategory === category ? null : category);
    setCurrentIndex(0); // Reset index when toggling category
  };

  const handleDotClick = (index) => {
    setCurrentIndex(index);
  };
  const clickpluse = (id) => {
    setShowData((prev) => (prev === id ? null : id));
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % raceMiniImages.length);
    }, 3000);
    return () => clearInterval(interval);
  }, [raceMiniImages]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => prevIndex % paceMiniImages.length);
    }, 3000);
    return () => clearInterval(interval);
  }, [paceMiniImages]);

  return (
    <>
      <div
        className="justify-center items-center flex flex-col bg-green-50"
        style={{
          //   backgroundImage: `url(${Herobg2})`,
          backgroundSize: "100% ",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="w-full bg-gradient-to-b from-green-100 to-slate-50">
          <Navbar />
          <div className="flex justify-center items-center">
            <div className="w-full text-center">
              <span className="font-barlowcondensed text-[64px] font-light text-cyan-950">
                Products
              </span>
              <p className="text-[20px] sm:text-[40px] text-green-500 font-barlowcondensed font-normal">
                Innovation in Every Mile
              </p>
            </div>
          </div>
        </div>
        <div className="">
          <div className="w-full h-12">
            {/* Race xL Button */}
            <div className="flex justify-center">
              <button
                onClick={() => handleToggleContent("race")}
                className="w-80 mt-4 mb-6 border-b-2 border-green-customs-600"
              >
                <div className=" bg-white h-11">
                  <p className="flex justify-center font-racingsansone w-full">
                    <span className="text-green-customs-600 mt-4">RACExL-</span>
                    <span className="text-black mt-4">Passenger Vehicle</span>
                    <div className="ml-20 mt-4">
                      {visibleCategory === "race" ? (
                        <FaChevronUp />
                      ) : (
                        <FaChevronDown />
                      )}
                    </div>
                  </p>
                </div>
              </button>
            </div>
          </div>

          {/* Render Race xL Content */}
          {visibleCategory === "race" && (
            <div
              className=""
              style={{
                backgroundImage: `url(${Product})`,
                backgroundSize: "100% ",
                backgroundPosition: "top",
                backgroundRepeat: "no-repeat",
              }}
            >
              <div className="flex flex-col justify-center items-center mt-16">
                {/* Main Image */}
                <img
                  src={miniImages[currentIndex]}
                  alt={`Race xL Image ${currentIndex + 1}`}
                  className="w-[300px] h-[210px]"
                />

                {/* Dots */}
                <div className="flex justify-center mt-4 gap-2">
                  {miniImages.map((_, index) => (
                    <div
                      key={index}
                      onClick={() => handleDotClick(index)}
                      className={`w-4 h-4 rounded-full cursor-pointer ${
                        currentIndex === index ? "bg-green-600" : "bg-gray-400"
                      }`}
                    ></div>
                  ))}
                </div>
              </div>
              <div className="border-2 border-green-200 rounded-md mt-3 ml-4 mr-4 mb-3 bg-green-50">
                <div className="ml-6">
                  <p className="font-normal pt-3 text-black">Single charge</p>

                  <div className="flex text-green-customs-600">
                    <p className="text-5xl lg:text-5xl font-barlowsemicondensed font-semibold">
                      170
                    </p>
                    <p className="text-base lg:text-[40px] items-end flex font-barlowsemicondensed font-bold text-[40px] leading-[48px]">
                      +miles
                    </p>
                  </div>
                  <div className="mb-3 ">
                    <p className="font-barlowcondensed tracking-normal text-sm md:text-base font-normal mr-2">
                      Discover a new way to navigate city streets with an
                      electric three wheeler(3WEV) designed for a driver and
                      three passengers. With its impressive range of 170+ miles
                      on a single charge, this eco-friendly vehicle is perfect
                      for daily commutes, deliveries, and urban adventures.
                    </p>
                  </div>
                </div>
              </div>

              <div>
                <p className="  mt-3 ml-2 text-left font-barlowcondensed tracking-normal text-sm md:text-base mb-3 font-normal">
                  The dashboard of a three-wheeler electric vehicle (3WEV) is
                  thoughtfully designed to prioritize functionality and
                  user-friendliness, ensuring the driver has quick access to all
                  the essential information and controls they need. At the heart
                  of this intuitive dashboard is a sleek, state-of-the-art smart
                  LED display that provides the driver with a wealth of
                  real-time data in a clear, easy-to-read format. With a
                  vibrant, high-resolution screen, this display keeps the driver
                  informed on crucial metrics like battery level, motor output,
                  and current speed, allowing them to monitor the vehicle's
                  performance and optimize their driving for maximum efficiency.
                  But the dashboard's smart features don't stop there - it also
                  incorporates a convenient USB charging port, enabling drivers
                  to keep their mobile devices powered up and connected on the
                  go. And for those who appreciate the tactile experience of
                  manual control, the 3WEV's dashboard features a
                  straightforward, ergonomic gear shifter that makes changing
                  gears a breeze, providing a seamless and responsive driving
                  experience. Thoughtfully designed to put all the necessary
                  information and controls right at the driver's fingertips, the
                  3WEV dashboard truly embodies the perfect blend of form and
                  function, ensuring a safe, efficient, and enjoyable ride every
                  time.
                </p>
              </div>
              <div className="pl-2">
                <p className="font-semibold">Conquer Hills with Ease</p>
                <p className="flex flex-col justify-center align-center text-left font-barlowcondensed tracking-normal text-sm md:text-base mb-3 font-normal">
                  Say goodbye to the anxiety of navigating steep hills. Our 3WEV
                  features a hill hold function that provides an extra boost of
                  power when you need it most. Experience a smooth, secure
                  ride no matter the terrain, and arrive at your destination
                  feeling confident and energized.
                </p>
              </div>
              <div className="pl-2">
                <p className="font-bold">Spacious Seating</p>
                <p className="flex flex-col justify-center align-center text-left font-barlowcondensed tracking-normal text-sm md:text-base mb-3 font-normal">
                  Comfortably accommodate a driver and three passengers, making
                  it ideal for carpooling, team transportation, or family outing
                </p>
              </div>
            </div>
          )}

          {/* Pace Button */}
          <div className="flex justify-center">
            <button
              onClick={() => handleToggleContent("pace")}
              className="w-80 mb-6 mt-4 bg-white border-b-2 border-green-customs-600"
            >
              <div className="bg-white h-11">
                <p className="flex justify-center mb-4 font-racingsansone">
                  <span className="text-green-customs-600 mt-4">PACE -</span>{" "}
                  <span className="text-black mt-4">Cargo</span>{" "}
                  <span className="text-black mt-4">Vehicle</span>
                  <div className="ml-28 mt-4">
                    {visibleCategory === "pace" ? (
                      <FaChevronUp className="ml-2 items-end" />
                    ) : (
                      <FaChevronDown className="ml-2 items-end" />
                    )}
                  </div>
                </p>
              </div>
            </button>
          </div>

          {/* Render Pace Content */}
          {visibleCategory === "pace" && (
            <div
              style={{
                backgroundImage: `url(${Product})`,
                backgroundSize: "100% ",
                backgroundPosition: "top",
                backgroundRepeat: "no-repeat",
              }}
            >
              <div className="flex flex-col justify-center items-center">
                {/* Main Image */}
                <img
                  src={miniImages[currentIndex]}
                  alt={`Pace Image ${currentIndex + 1}`}
                  className="w-[300px] h-[210px]"
                />

                {/* Dots */}
                <div className="flex justify-center mt-4 gap-2">
                  {miniImages.map((_, index) => (
                    <div
                      key={index}
                      onClick={() => handleDotClick(index)}
                      className={`w-4 h-4 rounded-full cursor-pointer ${
                        currentIndex === index ? "bg-green-600" : "bg-gray-400"
                      }`}
                    ></div>
                  ))}
                </div>
              </div>
              <div className="border-2 border-green-200 rounded-md mt-4 ml-4 mr-4 mb-3 bg-green-50">
                <p className=" font-barlowcondensed text-sm md:text-base mb-4 font-normal mt-4 ml-4 mr-4">
                  In today's fast-paced world, logistics and transportation play
                  a pivotal role in the success of businesses. When it comes to
                  transporting goods efficiently, three wheeler cargo vehicles
                  have emerged as an optimal choice for many. We will explore
                  the benefits of three wheeler cargo vehicles that offer good
                  driving space and a smooth, noiseless experience while
                  carrying heavy weights.
                </p>
              </div>

              <p className="  mt-3 ml-2 text-left font-barlowcondensed tracking-normal text-sm md:text-base mb-3 font-normal">
                The dashboard of a three-wheeler electric vehicle (3WEV) is
                thoughtfully designed to prioritize functionality and
                user-friendliness, ensuring the driver has quick access to all
                the essential information and controls they need. At the heart
                of this intuitive dashboard is a sleek, state-of-the-art smart
                LED display that provides the driver with a wealth of real-time
                data in a clear, easy-to-read format. With a vibrant,
                high-resolution screen, this display keeps the driver informed
                on crucial metrics like battery level, motor output, and current
                speed, allowing them to monitor the vehicle's performance and
                optimize their driving for maximum efficiency. But the
                dashboard's smart features don't stop there - it also
                incorporates a convenient USB charging port, enabling drivers to
                keep their mobile devices powered up and connected on the go.
                And for those who appreciate the tactile experience of manual
                control, the 3WEV's dashboard features a straightforward,
                ergonomic gear shifter that makes changing gears a breeze,
                providing a seamless and responsive driving experience.
                Thoughtfully designed to put all the necessary information and
                controls right at the driver's fingertips, the 3WEV dashboard
                truly embodies the perfect blend of form and function, ensuring
                a safe, efficient, and enjoyable ride every time.
              </p>
              <div className="flex flex-col justify-start items-start mt-5">
                <div className="">
                  <p className="font-normal text-base text-green-600 ml-2">
                    Why Choose Three Wheeler Cargo?
                  </p>
                  <p className="ml-2 mt-3 font-barlowcondensed">
                    Three Wheeler Cargo Vehicles are designed to provide a
                    balance between compactness and capability. Here are some
                    compelling reasons why they stand out in the market
                  </p>
                  {/* Section 1 */}
                  <hr className="mt-4 bg-black"></hr>

                  <div>
                    <div className="flex gap-24 items-start mt-3">
                      <p className="ml-2 font-normal text-black">
                        Spacious Driving Environment
                      </p>
                      <button
                        className="text-[25px] ml-8"
                        onClick={() => clickpluse(1)}
                      >
                        {showData === 1 ? "-" : "+"}
                      </button>
                    </div>
                    {showData === 1 && (
                      <p className="mt-2 ml-2">
                        <ProductContentArray />
                      </p>
                    )}
                    <hr className="mt-4 bg-black"></hr>
                  </div>

                  <div>
                    <div className="flex gap-28 items-start mt-3">
                      <p className="ml-2 font-normal text-black">
                        Smooth and Noiseless Ride
                      </p>
                      <button
                        className="text-[25px] ml-8"
                        onClick={() => clickpluse(2)}
                      >
                        {showData === 2 ? "-" : "+"}
                      </button>
                    </div>
                    {showData === 2 && (
                      <p className="mt-2 ml-2">
                        <ProductContentArray />
                      </p>
                    )}
                    <hr className="mt-3 bg-black"></hr>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
