import React, { useState } from "react";
import text from "../assets/Text.png";
import Swal from "sweetalert2"; // Import SweetAlert

const Input = () => {
  const [message, setMessage] = useState("");

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobile: "",
    city: "",
    message: "",
  });

  const [apiMessage, setApiMessage] = useState(""); // Store response message

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => {
      return { ...prev, [name]: value };
    });
  };

  const handleSubscribe = () => {
    setMessage("This feature is coming soon! Stay tuned for updates  !!!!.");
    setTimeout(() => setMessage(""), 3000); // Clears the message after 3 seconds
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(
        "https://ohmautomotives.com/send_contact_mail.php",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(formData),
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const result = await response.json();

      if (result.status === "success") {
        setApiMessage(result.message || "Submitted successfully!"); // Show success message in UI

        Swal.fire({
          text: result.message || "Submitted successfully!",
        });

        // Reset form fields after success
        setFormData({ name: "", email: "", mobile: "", city: "", message: "" });
      } else {
        setApiMessage("Failed to submit. Please try again.");
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Failed to submit. Please try again.",
        });
      }
    } catch (error) {
      console.error("Fetch error:", error);
      setApiMessage("Something went wrong! Please try again.");
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Something went wrong! Please try again.",
      });
    }

    // Remove the message after 3 seconds
    setTimeout(() => {
      setApiMessage("");
    }, 3000);
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="flex flex-row  ">
        <div className="flex w-[580px] flex-col mt-20">
          <div className="flex flex-row justify-end  gap-4 mb-4">
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              placeholder="Enter Your Name"
              required
              className="w-full sm:w-[350px] px-4 py-2 border border-green-custom rounded-lg focus:outline-none focus:ring-2 focus:ring-green-500 focus:border-transparent text-gray-700 placeholder-gray-400 shadow-lg"
            />

            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              placeholder="Enter Your Email"
              pattern="^[^\s@]+@[^\s@]+\.[^\s@]+$"
              required
              className="w-full sm:w-[350px] px-4 py-2 border border-green-custom rounded-lg focus:outline-none  focus:ring-2 focus:ring-green-500 focus:border-transparent text-gray-700 placeholder-gray-400 shadow-lg"
            />
          </div>
          <div className="flex flex-row justify-end gap-4">
            <input
              type="text"
              name="mobile"
              value={formData.mobile}
              onChange={handleChange}
              placeholder="Enter Your Mobile Number"
              pattern="^[0-9]{10}$"
              maxLength="10"
              required
              className="w-full sm:w-[350px] px-4 py-2 border border-green-custom rounded-lg focus:outline-none focus:ring-2 focus:ring-green-500 focus:border-transparent text-gray-700 placeholder-gray-400 shadow-lg"
            />

            <input
              type="text"
              name="city"
              value={formData.city}
              onChange={handleChange}
              placeholder="Enter Your City"
              className="w-full sm:w-[350px] px-4 py-2 border border-green-custom rounded-lg focus:outline-none focus:ring-2 focus:ring-green-500 focus:border-transparent text-gray-700 placeholder-gray-400 shadow-lg"
            />
          </div>
          <div className="mt-8 w-full">
            <textarea
              name="message"
              value={formData.message}
              onChange={handleChange}
              placeholder="Enter your message"
              className=" sm:w-full h-[140px] px-2 py-2 border border-green-custom rounded-lg resize-none focus:outline-none focus:ring-2 focus:ring-green-500 focus:border-transparent text-gray-700 placeholder-gray-400 shadow-lg"
            />
          </div>

          <div className="flex flex-row gap-4 mt-4 ">
            <div className="flex justify-start w-full mt-4 ">
              {/* <div className="rounded-[31px] bg-green-50  p-2 "> */}
              <div className="ml-[2px] flex  items-end  justify-center rounded-[31px] border-2 border-solid  border-green-custom bg-green-50 pb-[17px] pl-[22px] pr-6 pt-[18px] shadow-lg ">
                <button
                  type="submit"
                  className="font-roboto  pl-10 pr-10  text-center text-[20px] font-medium leading-[18px]"
                >
                  Submit
                </button>
              </div>
            </div>
            <div className="flex justify-start w-full mt-4 mr-48">
              <div className="ml-[2px] flex  items-end  justify-center rounded-[31px] border-2 border-solid  border-green-custom bg-green-50 pb-[17px] pl-[22px] pr-6 pt-[18px] shadow-lg">
                <div className="font-roboto  pl-10 pr-10  text-center text-[20px] font-medium leading-[18px]">
                  Reset
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-1/2 flex flex-col justify-center items-center mt-6">
          <div className="w-[300px] h-[340px] border-[1px] border-teal-400 bg-gradient-to-b from-teal-100 to-white flex flex-col justify-start items-center rounded-lg p-4">
            <img src={text} alt="textMessage" className="w-[80px] mt-8" />

            <p className="font-barlowcondensed text-[18px] font-semibold">
              Subscribe to our Newsletter
            </p>

            <input
              type="email"
              placeholder="Enter Your Email"
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:bg-green-custom text-gray-700 placeholder-gray-400 mt-4"
            />

            <button
              onClick={handleSubscribe}
              className="w-full flex items-center justify-center rounded-[10px] border-2 border-teal-500 bg-teal-50 py-3 px-6 shadow-sm shadow-teal-300 mt-4 font-barlowsemicondensed text-[20px] font-semibold"
            >
              Subscribe
            </button>
            {message && (
              <p className=" text-sm font-bold mt-2 text-center w-full">
                {message}
              </p>
            )}
          </div>
        </div>
      </div>
    </form>
  );
};
export default Input;
