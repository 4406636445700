import React, { useState } from "react";
import Swal from "sweetalert2"; // Import SweetAlert
import FrontAuto from "../assets/whiteAutoFront.png";
import Herobg2 from "../assets/cardcurve2.png";
import Navbar from "./NavBar";
import text from "../assets/Text.png";

export default function ContactPageMobileView() {
  const [message, setMessage] = useState("");

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobile: "",
    city: "",
    message: "",
  });

  const [apiMessage, setApiMessage] = useState(""); // Store response message

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => {
      return { ...prev, [name]: value };
    });
  };

  const handleSubscribe = () => {
    setMessage("This feature is coming soon! Stay tuned for updates.");
    setTimeout(() => setMessage(""), 3000); // Clears the message after 3 seconds
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(
        "https://ohmautomotives.com/send_contact_mail.php",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(formData),
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const result = await response.json();

      if (result.status === "success") {
        setApiMessage(result.message || "Submitted successfully!"); // Show success message in UI

        Swal.fire({
          text: result.message || "Submitted successfully!",
        });

        // Reset form fields after success
        setFormData({ name: "", email: "", mobile: "", city: "", message: "" });
      } else {
        setApiMessage("Failed to submit. Please try again.");
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Failed to submit. Please try again.",
        });
      }
    } catch (error) {
      console.error("Fetch error:", error);
      setApiMessage("Something went wrong! Please try again.");
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Something went wrong! Please try again.",
      });
    }

    // Remove the message after 3 seconds
    setTimeout(() => {
      setApiMessage("");
    }, 3000);
  };

  return (
    <div>
      <div
        className="justify-center items-center flex flex-col"
        style={{
          // backgroundImage: `url(${Herobg2})`,
          backgroundSize: "100%",
          // backgroundRepeat: "no-repeat",
        }}
      >
        <div className="w-full bg-gradient-to-b from-green-100 to-slate-50">
          <Navbar />
        </div>

        <div className="flex flex-col items-center justify-center mt-6">
          <div className="text-center">
            <span className="font-barlowcondensed tracking-light text-[64px] sm:text-[80px] font-light leading-tight text-cyan-950">
              Contact Us
            </span>
            <p className="text-center text-[16px] sm:text-[20px] leading-[28px] text-green-500 font-barlowcondensed font-normal">
              We’d Love to Hear from You!
            </p>
          </div>
        </div>

        {/* Display API message above the form */}
        {apiMessage && (
          <div className="mt-4 p-3 bg-gray-100 border border-gray-300 text-gray-800 rounded-md text-center">
            {apiMessage}
          </div>
        )}

        <form onSubmit={handleSubmit} className="mt-4 w-screen p-2">
          <div className="grid grid-cols-1 gap-4">
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              placeholder="Enter Your Name"
              className="w-full px-4 py-2 border border-green-custom rounded-lg focus:outline-none focus:ring-2 focus:ring-green-500 text-gray-700 placeholder-gray-400 shadow-lg"
              required
            />

            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              placeholder="Enter Your Email"
              pattern="^[^\s@]+@[^\s@]+\.[^\s@]+$"
              className="w-full px-4 py-2 border border-green-custom rounded-lg focus:outline-none focus:ring-2 focus:ring-green-500 text-gray-700 placeholder-gray-400 shadow-lg"
              required
            />

            <input
              type="text"
              name="mobile"
              value={formData.mobile}
              onChange={handleChange}
              placeholder="Enter Your Mobile Number"
              pattern="^[0-9]{10}$"
              maxLength="10"
              className="w-full px-4 py-2 border border-green-custom rounded-lg focus:outline-none focus:ring-2 focus:ring-green-500 text-gray-700 placeholder-gray-400 shadow-lg"
              required
            />

            <input
              type="text"
              name="city"
              value={formData.city}
              onChange={handleChange}
              placeholder="Select Your City"
              className="w-full px-4 py-2 border border-green-custom rounded-lg focus:outline-none focus:ring-2 focus:ring-green-500 text-gray-700 placeholder-gray-400 shadow-lg"
              required
            />
          </div>

          <div className="mt-4">
            <textarea
              name="message"
              value={formData.message}
              onChange={handleChange}
              placeholder="Enter your message"
              className="w-full h-[140px] px-4 py-2 border border-green-custom rounded-lg resize-none focus:outline-none focus:ring-2 focus:ring-green-500 text-gray-700 placeholder-gray-400 shadow-lg"
              required
            />
          </div>

          <div className="pt-2 text-center">
            <p>* All Fields are Mandatory</p>
          </div>

          <div className="mt-4 p-2 grid grid-cols-1 sm:grid-cols-2 gap-4">
            <button
              type="submit"
              className="flex items-center justify-center rounded-[31px] border-2 border-green-custom bg-green-50 py-3 px-6 shadow-lg font-roboto text-[20px] font-medium"
            >
              Submit
            </button>

            <button
              type="button"
              onClick={() =>
                setFormData({
                  name: "",
                  email: "",
                  mobile: "",
                  city: "",
                  message: "",
                })
              }
              className="flex items-center justify-center rounded-[31px] border-2 border-green-custom bg-green-50 py-3 px-6 shadow-lg font-roboto text-[20px] font-medium"
            >
              Reset
            </button>
          </div>
        </form>

        <div className="w-full flex justify-center mt-6">
          <img src={FrontAuto} alt="Auto" className="w-[1800px] h-auto" />
        </div>

        <div className="w-1/2 flex flex-col justify-center items-center mt-6">
          <div className="w-[300px] h-[340px] border-[1px] border-teal-400 bg-gradient-to-b from-teal-100 to-white flex flex-col justify-start items-center rounded-lg p-4">
            <img src={text} alt="textMessage" className="w-[80px] mt-8" />

            <p className="font-barlowcondensed text-[18px] font-semibold">
              Subscribe to our Newsletter
            </p>

            <input
              type="email"
              placeholder="Enter Your Email"
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:bg-green-custom text-gray-700 placeholder-gray-400 mt-4"
            />
            {message && (
              <p className="font-manrope text-sm  font-semibold mt-1 text-center w-full">
                {message}
              </p>
            )}
            <button
              onClick={handleSubscribe}
              className="w-full flex items-center justify-center rounded-[10px] border-2 border-teal-500 bg-teal-50 py-3 px-6 shadow-sm shadow-teal-300 mt-4 font-barlowsemicondensed text-[20px] font-semibold"
            >
              Subscribe
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
