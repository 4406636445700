import React, { useState, useEffect } from "react";
import Herobg2 from "../assets/cardcurve2.png";
import Navbar from "./NavBar";
import Gllery from "../assets/Gallery.png";
import { AppAvailable } from "./AppAvailable";
import Footer from "./Footer";
import GalleryImages from "./GalleryImages";
import Header from "./Header";
import MobileViewFooter from "./MobileViewFooter";
import GalleryMobileView from "./GalleryMobileView";

const Gallery = () => {
  const [isMobileView, setIsMobileView] = useState(false);

  // Update isMobileView based on screen size
  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 768); // Mobile view if width is less than 768px
    };

    // Set initial screen size
    handleResize();

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      {isMobileView ? (
        // Mobile view
        <>
          {/* <Header /> */}
          <div>
            <GalleryMobileView />
          </div>

          <div className="pt-10">
            <MobileViewFooter />
          </div>
        </>
      ) : (
        //DeskTop view
        <>
          <Header />
          <div
            className="justify-center items-center flex flex-col"
            style={{
              backgroundImage: `url(${Herobg2})`,
              backgroundSize: "100%",
              backgroundPosition: "left",
              backgroundRepeat: "no-repeat",
            }}
          >
            <div className="w-full">
              <Navbar />
            </div>

            <div className="flex flex-row justify-center items-center m-10">
              <div className="w-1/2 mt-24 mr-48">
                <span className="font-barlowcondensed tracking-light text-[128px] font-light leading-[153.6px] text-cyan-950">
                  Gallery
                </span>
                <p className="text-start text-[20px] w-[500px] sm:text-[40px] leading-[48px] text-green-500 font-barlowcondensed font-normal">
                  From Vision to Revolution
                </p>
              </div>
              <div className="w-1/2">
                <div className="flex flex-col items-center justify-start mt-14">
                  <img src={Gllery} alt="gallery" className="w-[345px]" />
                </div>
              </div>
            </div>
          </div>

          <div className="flex justify-center items-center">
            <div className="w-[1100px]">
              <GalleryImages />
            </div>
          </div>

          <AppAvailable />
          <Footer />
        </>
      )}
    </>
  );
};

export default Gallery;
