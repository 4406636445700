import React from "react";
import playstore from "../assets/googleplaystore-img2.png";
import appstore from "../assets/appleappstore-img.png";
import appimg from "../assets/app-img.png";

export const AppAvailable = () => {
  return (
    <div className="flex flex-col items-center justify-evenly bg-custom-gradient">
      <div className="flex flex-col md:flex-row gap-8 md:gap-20 items-center justify-center md:justify-between">
        {/* Text Section */}
        <div className="flex flex-col items-center md:items-start justify-around">
          <div className="text-center md:text-left">
            <p className="font-barlowcondensed tracking-normal font-normal text-[30px] md:text-[70px] text-[#FFFFFF] leading-[45px] md:leading-[105px]">
              OHM electric cabs
            </p>
            <p className="font-barlowcondensed tracking-normal font-light text-[15px] md:text-[25px] w-[300px] md:w-[420px] text-[#FFFFFF] leading-[25px] md:leading-[39px]">
              Experience Luxury, The Green Way with OHM Electric Cabs!
            </p>
          </div>
          {/* Links Section */}
          <div className="container flex flex-col md:flex-row items-center md:items-start flex-wrap mt-8 gap-4">
            <a href="https://play.google.com/store/search?q=ohm+electric+cabs&c=apps">
              <img
                src={playstore}
                alt="playstore"
                className="w-[180px] h-[50px] md:w-[212px] md:h-[67px]"
              />
            </a>
            <a href="https://apps.apple.com/us/app/ohm-electric-cabs/id1668975054">
              <img
                src={appstore}
                alt="appstore"
                className="w-[180px] h-[50px] md:w-[212px] md:h-[67px]"
              />
            </a>
          </div>
        </div>
        {/* Image Section */}
        <div>
          <img
            src={appimg}
            alt="appimg"
            className="w-[200px] h-[220px] md:w-[368px] md:h-[402px]"
          />
        </div>
      </div>
    </div>
  );
};
