import React from "react";
import OurJourneyAuto from "../svgs/ourjourney";

export default function OurJourney() {
  return (
    <div>
      {/* Section Title */}
      <div className="flex items-end justify-center pt-[7px]  flex-wrap ">
        <div className=" flex flex-col items-center text-center leading-[normal]">
          <span className="font-barlowcondensed tracking-normal text-[128px] font-light leading-[153.6px] text-cyan-950 ">
            Our Journey
          </span>
          <div className="flex h-8 flex-shrink-0 items-end justify-center">
            <div className="text-center text-[20px] sm:text-[40px] leading-[48px] text-green-500 font-barlowcondensed font-normal">
              Since Nov 2022
            </div>
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div className="relative z-0 flex flex-col items-center justify-center px-4 pt-11 sm:px-0">
        <div className="z-[1] flex flex-col self-stretch">
          <div className="flex flex-col sm:flex-row items-center justify-center gap-2">
            {/* Image on the left side */}
            <div className="flex items-center justify-center gap-4 order-1 mt-6 sm:mt-0">
              <OurJourneyAuto className="h-62 sm:h-83 bg-white flex-shrink-0 rounded-[15px] object-center drop-shadow-lg" />
            </div>

            {/* Text content on the right side */}
            <div className="flex flex-col w-full sm:w-[550px] items-start order-2 sm:order-1 sm:pl-8">
              {/* 2023 and First Prototype on the same line */}
              <div className="flex items-baseline text-left  ">
                <div>
                  <p className="text-[20px] sm:text-[48px] font-barlowcondensed font-light text-black text-left leading-[57.6px]">
                    2023
                  </p>
                  <p className="text-[30px] sm:text-[85px] font-barlowcondensed font-light text-[#0AAC76] leading-[102px] whitespace-nowrap  ">
                    First Prototype
                  </p>
                </div>
              </div>

              <div className="text-left font-barlowcondensed tracking-normal text-sm md:text-base  font-normal ">
                <p>
                  We are running 40 electrical vehicles of Mahindra E Verito
                  which are spacious, green vehicles with all safety features
                  and air conditioning.All the vehicles are brand new, & we have
                  a good response from the customers as we are clocking around
                  250 to 300 kms a day.
                </p>
                <div className="h-[19px]" />
                <p>
                  We are having charging yards with slow and fast charging
                  facility at different locations across India to support our
                  operations.
                </p>
              </div>

              {/* Who We Are button aligned at right */}
              <div className="flex justify-end w-full mt-4 ">
                {/* <div className="rounded-[31px] bg-green-50  p-2 "> */}
                <div className="ml-[2px] flex  items-end  justify-center rounded-[31px] border-2 border-solid  border-green-500 bg-green-50 pb-[17px] pl-[22px] pr-6 pt-[18px] shadow-lg shadow-green-300">
                  <div className="font-roboto  pl-10 pr-10  text-center text-[20px] font-medium leading-[18px]">
                    Who we are
                  </div>
                </div>
              </div>
              {/* </div> */}
            </div>
          </div>
        </div>
      </div>

      {/* Why Electrical, Mission, Strategy in a flex row */}
      <div className="flex flex-col sm:flex-row justify-center mt-12 gap-x-3 sm:gap-x-4">
        <div className="flex w-full sm:w-[300px] flex-col items-center gap-y-3">
          <div className="font-barlowcondensed font-normal text-center text-2xl sm:text-[48px] leading-[57.6px] tracking-normal text-[#197D71]">
            Why Electrical
          </div>
          <div className="text-center">
            <p className="text-xs sm:text-[16px] font-barlowcondensed tracking-normal font-normal leading-[19.49px]">
              OHM chose electric cabs to lead the way in sustainable
              transportation, driven by the vision of reducing environmental
              impact and offering an eco-friendly alternative to traditional
              fuel vehicles.
            </p>
          </div>
        </div>
        <div className="flex w-full sm:w-[356px] flex-col items-center gap-y-3">
          <div className="font-barlowcondensed font-normal text-center text-2xl sm:text-[48px] leading-[57.6px] tracking-normal text-[#197D71]">
            MISSION
          </div>
          <div className="text-center">
            <p className="text-xs sm:text-[16px] font-barlowcondensed tracking-normal font-normal leading-[19.49px]">
              Our mission is to be recognized as the global leader in Electric
              Vehicle Logistic Solutions for companies and the public and
              private sector by partnering with our clients to provide the best
              and most efficient Logistic solutions and to achieve service
              excellence.
            </p>
          </div>
        </div>
        <div className="flex w-full sm:w-[356px] flex-col items-center gap-y-3">
          <div className="font-barlowcondensed font-normal text-center text-2xl sm:text-[48px] leading-[57.6px] tracking-normal text-[#197D71]">
            STRATEGY
          </div>
          <div className="text-center">
            <p className="text-xs sm:text-[16px] font-barlowcondensed tracking-normal font-normal leading-[19.49px]">
              The strategy of OHM is to develop a viable & sustainable Electric
              Mobility Solutions globally. To be known as a Company which has
              made Electric Mobility Viable & reachable to every individual
              business.
            </p>
          </div>
        </div>
      </div>

      <div
        className="h-[2px] w-full items-center flex mx-auto m-8"
        style={{
          borderWidth: "2px",
          borderStyle: "solid",
          borderImage:
            "linear-gradient(270deg, #FFFFFF 3.14%, #10C06F 51.81%, #FFFFFF 98.57%) 1",
        }}
      ></div>
      <div className="flex flex-row  items-center justify-evenly">
        <div>
          {/* Content with gradient vertical lines */}
          <div className="flex  w-full justify-evenly gap-16 flex-col sm:flex-row">
            {/* Zero Emission */}
            <div className="text-center sm:w-[300px]  ">
              <div className="font-roboto  tracking-normalfont-normal text-2xl sm:text-[24px] text-teal-700  leading-[24px]  ">
                Zero Emission
              </div>
              <p className="text-xs font-barlowcondensed tracking-normal font-normal m-3 sm:text-[16px] leading-[16px]">
                Electric vehicles are environment friendly. <br /> An EV has
                zero exhaust emissions.
              </p>
            </div>

            {/* Gradient vertical line after Zero Emission */}
            <div className="relative hidden sm:flex items-center my-auto">
              <div
                className="w-0.5 h-[100px] border-r-2 border-transparent"
                style={{
                  borderImage:
                    "linear-gradient(270deg, #FFFFFF 3.14%, #10C06F 51.81%, #FFFFFF 98.57%) 1",
                }}
              ></div>
            </div>

            {/* Cheaper */}
            <div className="text-center">
              <div className="font-roboto font-normal text-2xl sm:text-[24px] tracking-normal text-teal-700  leading-[24px]">
                Cheaper
              </div>
              <p className="text-xs font-roboto font-normal tracking-normal sm:text-[16px] m-3 leading-[16px]">
                Owners of an EV have the advantage <br /> of much lower running
                costs.
              </p>
            </div>

            {/* Gradient vertical line after Cheaper */}
            <div className="relative hidden sm:flex items-center my-auto">
              <div
                className="w-0.5 h-[100px] border-r-2 border-transparent"
                style={{
                  borderImage:
                    "linear-gradient(270deg, #FFFFFF 3.14%, #10C06F 51.81%, #FFFFFF 98.57%) 1",
                }}
              ></div>
            </div>

            {/* Safety and Comfort */}
            <div className="text-center">
              <div className="font-roboto font-normal text-2xl tracking-normal sm:text-[24px] text-teal-700  leading-[24px]">
                Safety and comfort
              </div>
              <p className="text-xs font-roboto font-normal tracking-normal m-3 sm:text-[16px] leading-[16px]">
                EV features can improve safety. <br /> EVs tend to have a lower
                centre of gravity.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
