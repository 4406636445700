import React, { useState, useEffect } from "react";
import Contact from "./contact";
import Input from "./Input";
import { AppAvailable } from "./AppAvailable";
import Footer from "./Footer";
import ContactPageMobileView from "./ContactPageMobileView";
import MobileViewFooter from "./MobileViewFooter";

const ContactUs = () => {
  const [isMobileView, setIsMobileView] = useState(false);

  // Update isMobileView based on screen size
  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 768); // Mobile view if width is less than 768px
    };

    // Set initial screen size
    handleResize();

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      {isMobileView ? (
        // Mobile View
        <div>
          {/* <Contact />
          <div className="p-4">
            <Input />
          </div> */}
          <div>
            <ContactPageMobileView />
          </div>
          <div className="pt-10">
            <MobileViewFooter />
          </div>
        </div>
      ) : (
        // Desktop View
        <div>
          <Contact />
          <div className="flex justify-center items-center">
            <div className="w-[1100px]">
              <Input />
            </div>
          </div>
          <div className="mt-12">
            <AppAvailable />
          </div>
          <Footer />
        </div>
      )}
    </>
  );
};

export default ContactUs;
