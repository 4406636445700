import React from "react";
import HeroSection from "./HeroSection";
import Navbar from "./NavBar";
import Herobg2 from "../assets/cardcurve2.png";
import Card from "./Card";
import CardMobileView from "./CardMobileView";
import OurJourney from "./OurJourney";
import OurJourneyMobileView from "./OurJourneyMobileView";
import DashboardPrizeMobileView from "./DashboardPrizeMobileView";
import GalleryScreenMobileView from "./GalleryScreenMobileView";

export default function DashBoardMobileView() {
  return (
    <div>
      <div
        className="bg-green-50"
        style={{
          // backgroundImage: `url(${Herobg2})`,
          backgroundSize: "100% ",
          backgroundPosition: "top",
          //marginBottom: "100px !important",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="w-full bg-gradient-to-b from-green-100 to-slate-50">
          <Navbar />
          <div className="justify-center items-center flex ">
            {/* <Card /> */}
            <CardMobileView />
          </div>
        </div>
      </div>
      <OurJourneyMobileView />
      <GalleryScreenMobileView />
      <DashboardPrizeMobileView />
    </div>
  );
}
