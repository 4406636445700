import React from "react";
import Navbar from "./NavBar";
import Herobg2 from "../assets/cardcurve2.png";
import GalleryImagesMobileView from "./GalleryImagesMobileView";

export default function GalleryMobileView() {
  return (
    <div>
      <div
        className="justify-center items-center flex flex-col"
        style={{
          // backgroundImage: `url(${Herobg2})`,
          backgroundSize: "100%",
          //   backgroundPosition: "left",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="w-full bg-gradient-to-b from-green-100 to-slate-50">
          <Navbar />
          <div className="flex flex-col justify-center items-center">
            <div className="w-full text-center">
              <span className="font-barlowcondensed tracking-light text-[64px] font-light text-cyan-950">
                Gallery
              </span>
              <p className="text-[25px] sm:text-[40px] text-green-500 font-barlowcondensed font-normal">
                From Vision to Revolution
              </p>
            </div>
          </div>
        </div>

        <div className="flex justify-center items-center">
          <div className="w-[90%]">
            <GalleryImagesMobileView />
          </div>
        </div>
      </div>
    </div>
  );
}
