import React, { useState, useEffect } from "react";
import CardBg from "../assets/cardinnercurve.png";
import AutoBlue from "../assets/AutoBlue.png";
import Gear from "../svgs/gearicon";
import auto from "../assets/smallAuto.png";
import WhiteAuto from "../assets/WhiteAuto.png";
import goodsauto from "../assets/goods-auto.png";
import passengerauto from "../assets/passenger-auto.png";
//import vector1 from "../assets/Vector.png"

const autoImages = [AutoBlue, WhiteAuto];
const smallautoimages = [goodsauto, goodsauto];

export default function Card() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentImages, setCurrentImages] = useState(autoImages);
  const [showImages, setShowImages] = useState(true);
  const [showimages, setShowimages] = useState(true);

  // Function to go to the specific image based on dot click
  const goToSlide = (index) => {
    setCurrentIndex(index);
  };
  // Function to handle image click and switch to small images
  const handleImageClick = () => {
    setCurrentImages(showImages ? smallautoimages : autoImages);
    setShowImages((prev) => !prev);
    setShowimages(true);
  };
  // handel to second type of race xl autos
  const handelClickImages = () => {
    setCurrentImages(showimages ? smallautoimages : autoImages);
    setShowimages((prev) => !prev);
    setShowImages(true);
  };

  // Effect to change slides automatically every 2 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % currentImages.length);
    }, 3000);
    return () => clearInterval(interval);
  }, [currentImages.length]);

  return (
    <div className="justify-center border-2 border-[#70C1AF] text-white rounded-xl shadow-2xl bg-white w-full lg:w-[1140px] h-[748px] lg:h-auto mx-auto">
      <div
        style={{
          backgroundImage: `url(${CardBg})`,
          backgroundSize: "cover",
          backgroundPosition: "bottom",
          backgroundRepeat: "no-repeat",
          borderRadius: "7px",
          borderTopLeftRadius: "10px",
        }}
      >
        <div className="flex flex-row p-3 pl-10   ">
          <div className="lg:w-1/2 w-full lg:mb-0 text-center lg:text-left ">
            <p className="text-7xl font-racingsansone">Race XL</p>
            <p className="text-sm lg:text-[16px] leadind-[18px] text-white tracking-normal font-roboto font-normal">
              Step into the next generation of transportation with OHM Electric
              Auto. Offering a smooth, eco-friendly ride, our electric autos are
              designed to reduce emissions while maximizing efficiency and
              comfort. Whether for daily commutes or commercial needs, OHM
              Electric Auto is the smart, sustainable choice for a cleaner
              tomorrow.
            </p>

            {/* Other Products Section */}
            <div>
              <h1 className="text-white text-3xl py-4 font-barlowsemicondensed  tracking-normal leading-[38.4px]">
                Other Products{" "}
              </h1>
              <div className="flex justify-center lg:justify-start gap-5 flex-wrap">
                <div className="flex flex-col items-center">
                  <button onClick={handleImageClick}>
                    {showImages ? (
                      <img
                        src={auto}
                        alt="auto"
                        className="border-2 rounded-lg border-white w-[173px] h-[94px] bg-gradient-to-r from-[#0FA372] to-[#C3E7EC] px-6 py-2"
                      />
                    ) : (
                      <img
                        src={passengerauto}
                        alt="auto"
                        className="border-2 rounded-lg border-white w-[173px] h-[94px] bg-gradient-to-r from-[#0FA372] to-[#C3E7EC] px-6 py-2"
                      />
                    )}
                  </button>
                  <span className="text-white text-center font-barlowsemicondensed leading-[19.2px] font-semibold">
                    Race XL
                    <br /> (Pick-up Van)
                  </span>
                </div>
                <div className="flex flex-col items-center">
                  <button onClick={handelClickImages}>
                    {showimages ? (
                      <img
                        src={auto}
                        alt="auto"
                        className="border-2 rounded-lg border-white w-[173px] h-[94px] bg-gradient-to-r from-[#0FA372] to-[#C3E7EC] px-6 py-2"
                      />
                    ) : (
                      <img
                        src={passengerauto}
                        alt="auto"
                        className="border-2 rounded-lg border-white w-[173px] h-[94px] bg-gradient-to-r from-[#0FA372] to-[#C3E7EC] px-6 py-2"
                      />
                    )}
                  </button>

                  <span className="text-white text-center font-barlowsemicondensed leading-[19.2px] font-semibold">
                    Race XL
                    <br /> (Delivery Van)
                  </span>
                </div>
              </div>
            </div>
          </div>

          {/* Middle Section - Auto Image Carousel and Gear Modes */}
          <div className="lg:w-3/4 w-3/4 flex flex-col lg:flex-row items-center justify-center  lg:mb-0">
            {/* Carousel Section */}
            <div className="relative  mt-12">
              {" "}
              {/* Display current image */}
              <img
                src={currentImages[currentIndex]}
                alt="Auto"
                className="mb-4 lg:mb-0"
              />
              {/* Dots/Indicators */}
              <div className="flex justify-center mt-4 space-x-2">
                {(showImages || showimages ? autoImages : smallautoimages).map(
                  (_, index) => (
                    <button
                      key={index}
                      onClick={() => goToSlide(index)}
                      className={`w-3 h-3 rounded-full ${
                        currentIndex === index ? "bg-[#F1BF1C]" : "bg-gray-300"
                      }`}
                    ></button>
                  )
                )}
              </div>{" "}
            </div>

            {/* Gear Icons Section */}
            {/* <div className="hidden lg:flex lg:flex-col flex-row items-center justify-center space-x-4 lg:space-x-0 lg:space-y-2  ml-20 lg:mt-20 h-full pt-24">
              {[...Array(3)].map((_, index) => (
                <div className="flex flex-col items-center mt-8" key={index}>
                  <div className="border-2 border-[#70C1AF] p-4 rounded-xl">
                    <Gear />
                  </div>
                  <p className="text-sm text-black tracking-tighter ">
                    4 Drive Modes
                  </p>
                </div>
              ))}
            </div> */}
          </div>
        </div>
      </div>

      {/* Race XL Auto and Gear (with full-width white background at the bottom) */}
      <div className="bg-white p-4 rounded-lg shadow-md w-full flex flex-col items-center lg:flex-row lg:space-x-8 space-y-4 lg:space-y-0">
        <div className="flex w-full justify-evenly items-center  ">
          <div className="flex flex-col items-center">
            <p className=" lg:text-md text-black font-barlowsemicondensed font-normal text-base">
              Accelerator
            </p>
            <div className="flex text-black">
              <p className="lg:text-8xl font-barlowsemicondensed font-normal  leading-[115.2px]">
                40
              </p>
              <p className="text-sm lg:text-[40px] items-end flex font-barlowsemicondensed font-normal text-[40px] leading-[48px] ">
                Km/h
              </p>
            </div>
          </div>
          <div className="flex flex-col items-center border-x-[2px] border-[#70C1AF] px-6 lg:px-8">
            <p className=" lg:text-md text-black font-barlowsemicondensed font-normal text-base pr-16">
              Seating Capacity
            </p>
            <div className="flex text-black">
              <p className="text-3xl lg:text-8xl  font-barlowsemicondensed font-normal leading-[115.2px]">
                7
              </p>
              <p className="text-sm lg:text-[40px] items-end flex font-barlowsemicondensed font-normal text-[40px] leading-[48px]">
                +1 passengers
              </p>
            </div>
          </div>
          <div className="flex flex-col items-left">
            <p className=" lg:text-md text-black font-barlowsemicondensed font-normal text-base">
              Charging Time
            </p>
            <div className="flex text-black">
              <p className="text-3xl lg:text-8xl font-barlowsemicondensed font-normal leading-[115.2px]">
                6
              </p>
              <p className="text-sm lg:text-[40px] items-end flex font-barlowsemicondensed font-normal text-[40px] leading-[48px]">
                Hrs
              </p>
            </div>
          </div>
        </div>
        <button className="bg-[#FFB318] text-black text-[20px] lg:text-md   lg:w-1/4 h-14 rounded-full font-medium font-roboto leading-[23.44px] shadow-md shadow-slate-400">
          Book Test Drive
        </button>
      </div>
    </div>
  );
}
