import React, { useState, useEffect } from "react";
import CardBg from "../assets/cardinnercurve.png";
// import Auto from "../assets/MainAuto.png";
import AutoBlue from "../assets/AutoBlue.png";
import Gear from "../svgs/gearicon";
import auto from "../assets/smallAuto.png";
import WhiteAuto from "../assets/WhiteAuto.png";
import goodsauto from "../assets/goods-auto.png";
import passengerauto from "../assets/passenger-auto.png";
//import vector1 from "../assets/Vector.png"

const autoImages = [AutoBlue, WhiteAuto]; // Array of images for the carousel
const smallautoimages = [goodsauto, goodsauto];

export default function CardMobileView() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentImages, setCurrentImages] = useState(autoImages);
  const [showImages, setShowImages] = useState(true);
  const [showimages, setShowimages] = useState(true);

  // Function to go to the specific image based on dot click
  const goToSlide = (index) => {
    setCurrentIndex(index);
  };
  // Function to handle image click and switch to small images
  const handleImageClick = () => {
    setCurrentImages(showImages ? smallautoimages : autoImages);
    setShowImages((prev) => !prev);
    setShowimages(true);
  };
  // handel to second type of race xl autos
  const handelClickImages = () => {
    setCurrentImages(showimages ? smallautoimages : autoImages);
    setShowimages((prev) => !prev);
    setShowImages(true);
  };

  // Effect to change slides automatically every 2 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % currentImages.length);
    }, 3000);
    return () => clearInterval(interval);
  }, [currentImages.length]);

  return (
    <div className="ml-3 mr-3">
      <div className="justify-center border-2 border-[#70C1AF] text-white rounded-xl shadow-2xl bg-white w-full lg:w-[1140px] h-[710px] lg:h-auto mx-auto">
        <div
          style={{
            backgroundImage: `url(${CardBg})`,
            backgroundSize: "cover",
            backgroundPosition: "bottom",
            backgroundRepeat: "no-repeat",
            borderRadius: "7px",
            borderTopLeftRadius: "10px",
          }}
        >
          <div className="flex flex-row">
            <div className="w-full text-center pl-16">
              <div className="mt-4 w-48 h-16 mb-10">
                {/* Display current image */}
                <img
                  src={currentImages[currentIndex]}
                  alt="Auto"
                  className="mb-4 lg:mb-0"
                />

                {/* Dots/Indicators */}
                <div className="flex justify-center mt-4 space-x-2">
                  {(showImages || showimages
                    ? autoImages
                    : smallautoimages
                  ).map((_, index) => (
                    <button
                      key={index}
                      onClick={() => goToSlide(index)}
                      className={`w-3 h-3 rounded-full ${
                        currentIndex === index ? "bg-[#F1BF1C]" : "bg-gray-300"
                      }`}
                    ></button>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-32">
          <p className="text-3xl font-racingsansone text-black text-center">
            Race XL
          </p>
          <p className="text-sm lg:text-[16px] leadind-[10px] text-center text-black tracking-normal font-roboto font-normal">
            Step into the next generation of transportation with OHM Electric
            Auto. Offering a smooth, eco-friendly ride, our electric autos are
            designed to reduce emissions while maximizing efficiency and
            comfort.
          </p>
        </div>

        {/* Race XL Auto and Gear (with full-width white background at the bottom) */}
        <div className="bg-white w-full items-center lg:flex-row lg:space-x-8 space-y-4 lg:space-y-0">
          <div className="w-full items-center mt-4">
            <hr className="border-black m-2" />
            {/* Accelerator Section */}
            <div className="text-center flex justify-between">
              <p className="lg:text-md text-black font-barlowsemicondensed font-normal text-base ml-4">
                Accelerator
              </p>
              <div className="flex justify-evenly text-black">
                <p className=" font-barlowsemicondensed font-normal mr-4">
                  40 Km/h
                </p>
              </div>
            </div>

            <hr className="border-black m-2" />

            {/* Seating Capacity Section */}
            <div className="text-center flex justify-between">
              <p className="lg:text-md text-black font-barlowsemicondensed font-normal text-base ml-4">
                Seating Capacity
              </p>
              <div className="flex justify-evenly items-baseline text-black">
                <p className="font-barlowsemicondensed font-normal mr-4">
                  7 +1 passengers
                </p>
              </div>
            </div>

            <hr className="border-black m-2" />

            {/* Charging Time Section */}
            <div className="text-center flex justify-between ">
              <p className="lg:text-md text-black font-barlowsemicondensed font-normal text-base ml-4">
                Charging Time
              </p>
              <div className="flex justify-center items-baseline text-black">
                <p className="font-barlowsemicondensed font-normal mr-4">
                  6 Hrs
                </p>
              </div>
            </div>
            <hr className="border-black m-2" />
          </div>
          <div className="flex justify-center pt-4">
            <button className="bg-[#FFB318] text-black text-[20px] lg:text-md w-64 h-12 rounded-2xl font-medium font-roboto leading-[23.44px] shadow-md shadow-slate-400">
              Book Test Drive
            </button>
          </div>
        </div>
        <div>
          <h1 className="text-black text-xl ml-32 mt-4 mb-2 font-barlowsemicondensed  tracking-normal leading-[38.4px]">
            Other Products{" "}
          </h1>
          <div className="flex justify-center">
            {/* <div className=" items-center"></div> */}
            <div className="flex items-center bg-gradient-to-b from-[#C3E7EC] to-[#C3E7EC] w-full h-20">
              <button onClick={handelClickImages}>
                {showimages ? (
                  <img
                    src={auto}
                    alt="auto"
                    className=" w-full h-[94px] px-6 py-2"
                  />
                ) : (
                  <img
                    src={passengerauto}
                    alt="auto"
                    className=" w-full h-[94px]  px-6 py-2"
                  />
                )}
              </button>

              <span className="text-black text-center font-barlowsemicondensed leading-[19.2px] font-semibold ">
                Race XL
                <br /> (Delivery Van)
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
