import React from "react";
import Navbar from "./NavBar";
import Herobg2 from "../assets/cardcurve2.png";
import quotes from "../assets/quotes.png";
import SmallCards from "./SmallCards";
import MobileViewCards from "./MobileViewCards";
import DataArray from "./DataArray";
import KarnaImg from "../assets/karna.png";
import LInkDin from "../assets/linkedin.png";
import Instagram from "../assets/insta.png";

export default function ResearchDevelopmentMobileView() {
  return (
    <div>
      <div
        className="justify-center items-center flex flex-col"
        style={{
          // backgroundImage: `url(${Herobg2})`,
          backgroundSize: "100%",
          //   backgroundPosition: "left",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="w-full bg-gradient-to-b from-green-100 to-slate-50">
          <Navbar />
          <div className="flex flex-row justify-center items-center">
            <div className=" text-center">
              <span className="font-barlowcondensed text-[50px] font-light text-cyan-900 leading-none">
                Research & Development
              </span>
              <p className="text-center text-[22px] sm:text-[40px] text-green-600 font-barlowcondensed font-normal">
                Creating What’s Next
              </p>
            </div>
          </div>
        </div>

        <div className="flex justify-center items-center">
          <div className="flex flex-col justify-center items-center text-start mt-8 ml-4 font-barlowcondensed tracking-normal text-[14px] md:text-base font-medium text-black">
            <p>
              Founded in mid-2020, OHM R&D has embraced the age-old adage,
              “Necessity is the mother of invention,” to fuel its innovative
              drive in the electric vehicle (EV) sector. At a time when the
              automotive industry faced unprecedented challenges, our team
              harnessed the power of creativity and ingenuity to develop
              sophisticated solutions that meet the evolving demands of the
              market and our customers.
            </p>
          </div>
        </div>
      </div>
      <div className="p-2">
        <div className="flex justify-start py-4">
          <img
            src={quotes}
            alt="quotes"
            className="object-contain"
            style={{
              maxWidth: "50px",
              maxHeight: "50px",
              transform: "scaleX(-1)", // Flip the image horizontally
            }}
          />
        </div>
        <div className="pl-6 text-[30px]  font-barlowcondensed  font-light italic text-gray-600">
          <p className="text-center text-[30px]  font-barlowcondensed  font-light italic text-gray-600">
            Our commitment to <br />
            innovation has led to the
            <br /> creation of a range of
            <br />
            cutting-edge products
            <br /> designed to enhance the
            <br /> electric driving experience.
            <br />
            <p>
              Some of our notable
              <br /> contributions include:
            </p>
          </p>
        </div>
        <div className="flex justify-end py-4">
          <img
            src={quotes}
            alt="quotes"
            className="object-contain"
            style={{
              maxWidth: "50px",
              maxHeight: "50px",
              transform: "scaleY(-1)", // Flip the image horizontally
            }}
          />
        </div>
      </div>
      <div className="">
        <MobileViewCards />
      </div>
      <div className="flex justify-center items-center pt-6">
        <div className=" w-[1100px] p-3 flex flex-col justify-center align-center text-left font-barlowcondensed tracking-normal text-sm md:text-base  font-normal">
          <p>
            Our Electric Vehicle (EV) Development R&D team is a specialized
            group focused on researching, designing, and developing electric
            vehicles, with a particular emphasis on improving their performance,
            efficiency, and sustainability. This team works on various aspects
            of EVs, including the design of the vehicle itself, battery
            technology, electric drivetrains, charging infrastructure, and
            software systems that enhance the driving experience.
          </p>
        </div>
      </div>
      <div className="pt-7 pl-2 pr-2">
        <DataArray />
      </div>
      <div className="p-2">
        <div className="flex justify-start py-4">
          <img
            src={quotes}
            alt="quotes"
            className="object-contain"
            style={{
              maxWidth: "50px",
              maxHeight: "50px",
              transform: "scaleX(-1)", // Flip the image horizontally
            }}
          />
        </div>
        <div className="pl-4 text-[30px]  font-barlowcondensed  font-light italic text-gray-600">
          <p className="text-center text-[30px]  font-barlowcondensed  font-light italic text-gray-600">
            <p>
              The first auto assembly was designed & developed by early
              <br />
            </p>
            <p>
              {" "}
              2021, obtained first certificated for Powertrain (AIS-041) by{" "}
              <br />
            </p>
            <p>
              {" "}
              March 31st, 2022.  From then, OHM Homologation process rapid its
              intensity & obtained
            </p>
            <p>
              {" "}
              New Regulation battery (AIS-156) certification, ARAI certification{" "}
              <br />
            </p>
            <p> for both L5 - Cargo & Passenger by Mid 2023.</p>
          </p>
        </div>
        <div className="flex justify-end py-4">
          <img
            src={quotes}
            alt="quotes"
            className="object-contain"
            style={{
              maxWidth: "50px",
              maxHeight: "50px",
              transform: "scaleY(-1)", // Flip the image horizontally
            }}
          />
        </div>
      </div>
      <div>
        <div className=" flex flex-col item-center justify-center ">
          <img
            src={KarnaImg}
            alt="karnasir"
            className="w-[100px] ml-36 border-green-600 border-2 rounded-full"
          />
          <div className="ml-32">
            <p className="pt-2">M. Karunakar Reddy</p>
            <p className="">Co-Founder & CEO</p>
            <div className="flex flex-row ml-6 gap-2 mt-2">
              <img src={LInkDin} alt="linkdin" />
              <img src={Instagram} alt="instagram" />
            </div>
          </div>
          <div className=" justify-center items-center">
            <p className="mt-6 font-barlowsemicondensed font-semibold text-[24px] ml-32">
              Team Head
            </p>
            <p className="text-[24px] sm:text-[40px] font-barlowcondensed  font-light italic text-gray-600  mb-6 ml-28">
              Meet Mr. Karan Reddy:
              <br /> Innovator Behind OHM’s <br /> Visionary Journey
            </p>
          </div>
        </div>
      </div>
      <div>
        {" "}
        <p className=" flex flex-col justify-center align-center font-barlowcondensed tracking-normal text-sm font-normal ml-4">
          Mr. Karan Reddy’s dedication, vision, and trust in his team set the
          foundation for OHM’s groundbreaking journey in revolutionizing the
          automotive sector. As the company continues to innovate and expand its
          horizons with his enthusiastic leadership, India stands a great chance
          at making its mark globally with extraordinary, “Made-in-India”
          products that reflect quality and resilience. With Mr. Reddy at the
          helm, the future of OHM looks exceedingly bright and filled with
          promise.
        </p>
        <div className="pt-6">
          <p className=" flex flex-col justify-center align-center font-barlowcondensed tracking-normal text-sm font-normal ml-4">
            Research and Development (R&D) is a critical phase in the process of
            innovation and advancement within businesses, organizations, and
            industries. It involves systematic work undertaken to increase
            knowledge, develop new products, services, or technologies, and
            improve existing ones. The main objective of R&D is to create new
            solutions that can drive progress, efficiency, and profitability
            while meeting market demands or solving specific problems.
          </p>
        </div>
      </div>
      <div>
        <p className="text-center text-[28px] sm:text-[40px] leading-[40px] font-barlowcondensed  font-light italic text-gray-500 mt-10 mb-6">
          OHM R&D: Innovating Electric
          <br /> Vehicles Through Necessity
        </p>
      </div>
      <div>
        <p className=" flex flex-col justify-center align-center font-barlowcondensed tracking-normal text-sm font-normal ml-4">
          At OHM R&D, our journey has just begun. We understand that the
          landscape of transportation is continuously changing, and we remain
          steadfast in our mission to lead the evolution of electric vehicles
          with products that prioritize sustainability, innovation, and customer
          satisfaction.
        </p>
        <div className="pt-6">
          <p className=" flex flex-col justify-center align-center font-barlowcondensed tracking-normal text-sm font-normal ml-4">
            Through a commitment to relentless research and development, we are
            not only addressing the necessities of today but also pioneering the
            solutions for a better tomorrow in the realm of electric mobility.
            Join us as we drive forward into a greener future!
          </p>
        </div>
      </div>
    </div>
  );
}
