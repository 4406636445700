import React from "react";
import CsrImage from "../assets/MaskGroup.png";
import CsrImages from "./CsrImages";
import Herobg2 from "../assets/cardcurve2.png";
import Navbar from "./NavBar";
import CsrImageMobileView from "./CsrImageMobileView";

export default function CsrMobileView() {
  return (
    <div>
      <div
        // className=" bg-gradient-to-b from-teal-50 to-white"
        style={{
          // backgroundImage: `url(${Herobg2})`,
          backgroundSize: "100% ",
          //   backgroundPosition: "left",
          //marginBottom: "100px !important",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="w-full bg-gradient-to-b from-green-100 to-slate-50">
          <Navbar />
          <div className="flex justify-center items-center text-center">
            <div className="w-full pb-4">
              <p className="font-barlowcondensed tracking-light text-[70px] font-light text-cyan-950 block">
                CSR
              </p>
              <p className="text-[20px] sm:text-[40px] text-green-500 font-barlowcondensed font-normal">
                Creating Value for People and Planet
              </p>
            </div>
          </div>
        </div>

        <div className="flex justify-start items-start">
          <div className="w-[1100px]">
            <p className=" px-6 mx-4 text-start sm:text-start text-[30px] sm:text-[30px] leading-[48px] font-barlowcondensed font-light italic text-gray-600 flex flex-col">
              Dear Sir,
            </p>
            <div className="pt-6">
              <p className=" px-6 mx-4 text-start sm:text-start text-[20px] sm:text-[16px] leading-[19px] font-barlowcondensed italic text-[#666666] flex flex-col">
                On 1st March'2022, We were fortunate to represent OHM and the
                Organization’s sensitivity to a social cause at FCN - Foundation
                for Children in Need. The commendable job and energy and time
                given by Smt. Dr Geetha and Shri T Reddy Garu cannot be
                explained in words. The facility at Shaad Nagar is a loving and
                caring home to people with difficulties, different abilities and
                sensibilities. Yesterday there were many children and adults in
                attendance, suffering from Muscular Dystrophy as part of the
                donation drive. Two Senior members, themselves suffering from
                this concern were the main coordinators for the drive. Truly,
                all of them were grateful and cheerful on receiving some basic
                necessities contributed by OHM, this was possible only because
                of you all. Thank You Once Again.
              </p>
              <div className="flex items-center pt-8 pb-10">
                <p className="px-6 mx-4 text-center sm:text-start text-[20px] sm:text-[16px] leading-[19px] font-barlowcondensed italic text-[#666666] flex flex-col">
                  Our sincere Thank You to Mrs. Parneetha for helping us
                  coordinate this effort from beginning to end and who of course
                  is a huge support to the foundation as well! As an
                  organization, we can support different timelines in many ways
                  and with different teams as well.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div>
          <CsrImageMobileView />
        </div>
      </div>
    </div>
  );
}
